import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchCreditNoteById } from "../../api/creditNotes/creditNotesSlice";
import { Box, Paper, Typography, useTheme, Grid } from "@mui/material";
import "../../styles/DocumentDetails.css";
import CustomTable from "../../components/CustomTable";
import TopBar from "../../components/TopBar";
import DetailsPageActions from "./details/DetailsPageAction";
import formatCurrencyWords from "../../utils/formatCurrencyWords";
import ProfileCard from "../common/ProfileCard";
import SummaryCard from "../common/SummaryCard";
import InfoCard from "../common/InfoCard";
import { getCountryName, getStateName, getTaxCode } from "../../utils/helper";
import useDocumentTitle from "../../hooks/useDocumentTitle";
import IrbmStatusCard from "../common/IrbmStatusCard";

export default function CreditNoteDetails({ config }) {
  useDocumentTitle("Credit Note Details");
  const { document_id } = useParams();
  const dispatch = useDispatch();
  const taxCodes = useSelector((state) => state.taxTypes.taxTypes);
  const countryCodes = useSelector((state) => state.countryCodes.countryCodes);
  const states = useSelector((state) => state.states.states);
  const creditNote = useSelector(
    (state) => state.creditNotes.selectedCreditNote,
  );

  const loading = useSelector((state) => state.creditNotes.loading);
  const theme = useTheme();

  useEffect(() => {
    if (document_id) {
      dispatch(fetchCreditNoteById(document_id));
    }
  }, [dispatch, document_id]);

  if (loading) {
    return (
      <Box className="centered-box">
        <Typography variant="h6" component="div">
          Loading...
        </Typography>
      </Box>
    );
  }

  const {
    irbmSection,
    creditNoteSection,
    itemSection,
    actions,
    title,
    sellerSection,
    buyerSection,
    paymentSection,
    summarySection,
    grandTotalInWords,
  } = config;

  return (
    <Box>
      <TopBar title={title}>
        <DetailsPageActions actions={actions} />
      </TopBar>

      <Paper className="invoice-details-paper">
        {irbmSection && (
          <IrbmStatusCard irbmSection={irbmSection} document={creditNote} />
        )}

        {creditNote && (
          <InfoCard document={creditNote} data={creditNoteSection} />
        )}

        <Grid
          container
          spacing={2}
          className="vendor-biller-payment-container"
          sx={{ marginTop: theme.spacing(1), alignItems: "stretch" }}
        >
          {creditNote && (
            <Grid item xs={12} md={creditNote?.payment ? 4 : 6}>
              <ProfileCard
                title={sellerSection.title}
                subtitle={sellerSection.subtitle}
                fields={sellerSection.fields}
                document={{
                  ...creditNote,
                  billed_by: {
                    ...creditNote.billed_by,
                    state: getStateName(creditNote?.billed_by.state, states),
                    country_code: getCountryName(
                      creditNote?.billed_by.country_code,
                      countryCodes,
                    ),
                  },
                }}
                applyRightMargin={true}
                fixedHeight={true}
              />
            </Grid>
          )}
          {creditNote && (
            <Grid item xs={12} md={creditNote?.payment ? 4 : 6}>
              <ProfileCard
                title={buyerSection.title}
                subtitle={buyerSection.subtitle}
                fields={buyerSection.fields}
                document={{
                  ...creditNote,
                  billed_to: {
                    ...creditNote.billed_to,
                    state: getStateName(creditNote?.billed_to.state, states),
                    country_code: getCountryName(
                      creditNote?.billed_to.country_code,
                      countryCodes,
                    ),
                  },
                }}
                applyRightMargin={true}
                fixedHeight={true}
              />
            </Grid>
          )}
          {creditNote && creditNote?.payment && (
            <Grid item xs={12} md={4}>
              <ProfileCard
                title={paymentSection.title}
                subtitle={paymentSection.subtitle}
                fields={paymentSection.fields}
                document={creditNote}
              />
            </Grid>
          )}
        </Grid>

        <Box className="item-details-container">
          <Typography variant="h6" gutterBottom>
            Item Details
          </Typography>

          {creditNote && (
            <CustomTable
              columns={itemSection}
              filteredData={creditNote?.item_details?.map((item) => ({
                ...item,
                tax_category_code: getTaxCode(item.tax_category_code, taxCodes),
              }))}
              currentPage={1}
              totalCount={0}
              handleChangePage={() => {}}
              handleChangeRowsPerPage={() => {}}
              actions={[]}
              isPaginationEnabled={false}
            />
          )}
        </Box>

        <SummaryCard rows={summarySection} document={creditNote} />

        <hr className="summary-divider" />

        {creditNote && grandTotalInWords && (
          <Box className="invoice-footer">
            <div className="invoice-summary-item">
              <span className="invoice-summary-label">
                {grandTotalInWords.label}
              </span>
              <span className="invoice-summary-value invoice-summary-value-bold">
                {formatCurrencyWords(
                  creditNote.grand_total,
                  creditNote.document_currency_code,
                )}
              </span>
            </div>
          </Box>
        )}
      </Paper>
    </Box>
  );
}
