import React, { useState } from "react";
import { Box, Grid } from "@mui/material";
import TopBar from "../../../components/TopBar";
import AddSupplierActions from "./AddSupplierActions";
import { useSelector } from "react-redux";
import useDocumentTitle from "../../../hooks/useDocumentTitle";
import {
  convertConfigToFormData,
  getRequiredFields,
} from "../../../utils/helper";
import CustomDivider from "../../../components/CustomDivider";
import CustomStringInput from "../../../components/CustomStringInput";
import CustomSelectInput from "../../../components/CustomSelectInput";

export default function SupplierForm({ config }) {
  useDocumentTitle("New Supplier Form");
  const {
    title,
    actions,
    supplierFormData,
    addressFormData,
    businessIdentifiersFormData,
  } = config;
  const [formData, setFormData] = useState(
    convertConfigToFormData([
      ...supplierFormData,
      ...addressFormData,
      ...businessIdentifiersFormData,
    ]),
  );
  const [errors, setErrors] = useState({});
  const requiredFields = getRequiredFields([
    supplierFormData,
    addressFormData,
    businessIdentifiersFormData,
  ]);
  const states = useSelector((state) => state.states.states);
  const msicCodes = useSelector((state) => state.msicCodes.msicCodes);
  const countryCodes = useSelector((state) => state.countryCodes.countryCodes);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevData) => ({ ...prevData, [name]: value }));

    setErrors((prevErrors) => {
      const newErrors = { ...prevErrors };
      if (newErrors[name]) {
        delete newErrors[name];
      }
      return newErrors;
    });
  };

  const getOptions = (field) => {
    const optionsMap = {
      country_code: countryCodes,
      state: states,
      msic_code: msicCodes,
    };
    return optionsMap[field.key];
  };

  const fieldTypeMap = {
    StringInput: CustomStringInput,
    StaticSelectInput: CustomSelectInput,
    DynamicSelectInput: CustomSelectInput,
  };

  const renderField = (field) => {
    if (field.key === "state" && formData.country_code !== "MYS") {
      return (
        <CustomStringInput
          key={field.key}
          field={field}
          formData={formData}
          handleChange={handleChange}
          errors={errors}
        />
      );
    }

    const FieldComponent = fieldTypeMap[field.type];
    const options =
      field.type === "StaticSelectInput" ? field.options : getOptions(field);

    return (
      <FieldComponent
        key={field.key}
        field={field}
        formData={formData}
        handleChange={handleChange}
        errors={errors}
        options={options}
        labelKey={field.options?.labelKey || "label"}
        valueKey={field.options?.valueKey || "value"}
      />
    );
  };

  return (
    <Box sx={{ p: 4 }}>
      <TopBar title={title}>
        <AddSupplierActions
          formData={formData}
          requiredFields={requiredFields}
          setErrors={setErrors}
          actions={actions}
        />
      </TopBar>

      <Grid container spacing={2}>
        {supplierFormData.map(renderField)}

        <Grid item xs={12}>
          <CustomDivider title="Address" />
        </Grid>

        {addressFormData.map(renderField)}

        <Grid item xs={12}>
          <CustomDivider title="Business Identifiers" />
        </Grid>

        {businessIdentifiersFormData.map(renderField)}
      </Grid>
    </Box>
  );
}
