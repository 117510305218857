import React from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  useTheme,
  IconButton,
  Box,
} from "@mui/material";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import { useDispatch } from "react-redux";
import { logout } from "../api/auth/authSlice";
import { useNavigate } from "react-router-dom";
import logo from "../assets/logo/marmin_logo.png";

function Header() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogout = () => {
    dispatch(logout());
    navigate("/login", { replace: true });
  };

  return (
    <AppBar
      position="fixed"
      sx={{
        zIndex: theme.zIndex.drawer + 1,
        backgroundColor: theme.palette.common.white,
        boxShadow: theme.shadows[3],
      }}
    >
      <Toolbar>
        <img
          src={logo}
          height="35"
          alt="Logo"
          style={{ marginRight: theme.spacing(2) }}
        />
        <Typography
          variant="h5"
          noWrap
          component="div"
          color="black"
          sx={{ fontWeight: theme.typography.fontWeightBold }}
        ></Typography>
        <Box sx={{ marginLeft: "auto", display: "flex", alignItems: "center" }}>
          <IconButton
            onClick={handleLogout}
            sx={{
              borderRadius: 0,
              "&:hover": {
                backgroundColor: theme.palette.action.hover,
              },
            }}
          >
            <ExitToAppIcon sx={{ marginRight: 1 }} />
            Logout
          </IconButton>
        </Box>
      </Toolbar>
    </AppBar>
  );
}

export default Header;
