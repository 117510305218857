import React from "react";
import { Box, Typography, useTheme } from "@mui/material";
import { get } from "lodash";

const InfoCard = ({ document, data }) => {
  const theme = useTheme();
  return (
    <Box
      className="flex-info-item"
      sx={{
        margin: theme.spacing(0, 0.5, 0, 0.5),
        gap: theme.spacing(1),
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: "4px",
      }}
    >
      {data.map((item, index) => (
        <div key={index} className="info-card-item">
          <Typography
            variant="title"
            sx={{ color: theme.palette.text.primary }}
          >
            {item.label}
          </Typography>

          <Typography
            variant="body2"
            sx={{ color: theme.palette.text.primary, fontWeight: "bold" }}
          >
            {item.key === "document_date"
              ? `${get(document, item.key)} ${get(document, "document_time")}`
              : get(document, item.key) || "-"}
          </Typography>
        </div>
      ))}
    </Box>
  );
};

export default InfoCard;
