import React from "react";
import { Paper, Typography, Box, useTheme } from "@mui/material";
import { get } from "lodash";

const IrbmStatusCard = ({ irbmSection, document }) => {
  const theme = useTheme();

  const irbmStatus = get(document, irbmSection[0]["key"]);
  const documentUUID = get(document, irbmSection[1].key);
  const validationSteps = get(document, irbmSection[2].key);

  const getIrbmStatusBackgroundColor = (status) => {
    switch (status) {
      case "Valid":
        return "#E6F4EA";
      case "Invalid":
        return "#FEECEC";
      case "Submitted":
        return "#E6F4FF";
      case "Cancelled":
        return "#E9ECEF";
      default:
        return "transparent";
    }
  };

  return (
    irbmStatus && (
      <Paper
        sx={{
          margin: theme.spacing(0, 0.5, 3.5, 0.5),
          gap: theme.spacing(2),
          padding: theme.spacing(2),
          border: `1px solid ${theme.palette.divider}`,
          backgroundColor: getIrbmStatusBackgroundColor(irbmStatus),
          borderRadius: "4px",
        }}
      >
        <Typography>
          <strong>{irbmSection[0]["label"]}:</strong> {irbmStatus}{" "}
        </Typography>
        <Typography>
          <strong>{irbmSection[1]["label"]}:</strong> {documentUUID}
        </Typography>
        {validationSteps?.length > 0 && (
          <Box>
            {validationSteps.map((step, index) => (
              <Box key={index} sx={{ marginBottom: theme.spacing(1) }}>
                <Typography variant="subtitle1">
                  Error Code: {step.error.code}
                </Typography>
                {step.error.details.map((detail, detailIndex) => (
                  <Typography key={detailIndex} variant="body2">
                    {detail?.code ? <strong>{detail.code}: </strong> : ""}
                    {detail.message}
                  </Typography>
                ))}
              </Box>
            ))}
          </Box>
        )}
      </Paper>
    )
  );
};
export default IrbmStatusCard;
