import React, { useState } from "react";
import { IconButton, Menu } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { get } from "lodash";
import DownloadDocumentAction from "../common/DownloadDocumentAction";
import { GoToDetailsAction } from "../common/GoToDetailsAction";
import DownloadXMLAction from "../common/DownloadXMLAction";

const DebitNoteActions = ({
  actions: configuredActions,
  row,
  documentStatus,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getActions = (actions) => {
    return actions.map((action, index) => {
      switch (action.action) {
        case "details":
          return (
            <GoToDetailsAction
              key={`details-${index}`}
              action={action}
              document={row}
            />
          );
        case "download-pdf":
          if (get(row, documentStatus.key) === "Valid") {
            return (
              <DownloadDocumentAction
                key={`download-pdf-${index}`}
                action={action}
                documentContent={row}
                documentType="debit_note"
              />
            );
          }
          return null;
        case "download-xml":
          if (get(row, documentStatus.key) === "Valid") {
            return (
              <DownloadXMLAction
                key={`download-xml-${index}`}
                base64XML={get(row, action.key)}
                documentNumber={row?.document_number}
                label={action.label}
              />
            );
          }
          return null;
        default:
          console.log("Unknown action");
          return null;
      }
    });
  };

  return (
    <>
      <IconButton onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {getActions(configuredActions)}
      </Menu>
    </>
  );
};

export default DebitNoteActions;
