import React from "react";
import { Box, useTheme } from "@mui/material";
import SubHeader from "./SubHeader";
import "../styles/TopBar.css";

const TopBar = ({ title, subTitle, children }) => {
  const theme = useTheme();
  return (
    <Box className="top-bar" sx={{ padding: theme.spacing(1) }}>
      <Box className="subheader-container">
        <SubHeader title={title} subTitle={subTitle} />
      </Box>
      {children}
    </Box>
  );
};

export default TopBar;
