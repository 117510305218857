import { useEffect, useState } from "react";
import { Config } from "../config";

export function useConfig() {
  const defaultConfig = Config[process.env.REACT_APP_REGION];
  const [config, setConfig] = useState(defaultConfig);

  useEffect(() => {
    // Merge the default config with region-specific config
    const mergedConfig = { ...defaultConfig };
    setConfig(mergedConfig);
  }, [defaultConfig]);

  return config;
}
