import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Button,
  TextField,
  Typography,
  Container,
  CssBaseline,
  FormControlLabel,
  Checkbox,
  InputAdornment,
} from "@mui/material";
import { AccountCircle, Lock } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { loginRequest } from "./api/auth/authSlice";
import "./styles/Login.css";
import useDocumentTitle from "./hooks/useDocumentTitle";

const Login = ({ redirectTo }) => {
  useDocumentTitle("Sign In");
  const [authInfo, setAuthInfo] = useState({ email: "", password: "" });
  const [showPassword, setShowPassword] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { error, loading, isAuthenticated } = useSelector(
    (state) => state.auth,
  );

  const handleChange = (e) => {
    const { name, value } = e.target;
    setAuthInfo((prev) => ({ ...prev, [name]: value }));

    if (formErrors[name]) {
      setFormErrors((prev) => ({ ...prev, [name]: "" }));
    }
  };

  const handleBlur = (e) => {
    const { name, value } = e.target;
    let errors = { ...formErrors };

    if (!value) {
      errors[name] =
        name === "email"
          ? "Please enter your email address."
          : "Password is required.";
    } else {
      errors[name] = "";
    }

    setFormErrors(errors);
  };

  const handleLogin = (e) => {
    e.preventDefault();
    if (validateForm()) {
      dispatch(loginRequest(authInfo));
    }
  };

  const validateForm = () => {
    let errors = {};
    if (!authInfo.email) {
      errors.email = "Please enter your email address.";
    }
    if (!authInfo.password) {
      errors.password = "Password is required.";
    }
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  useEffect(() => {
    if (isAuthenticated) {
      navigate(`/${redirectTo}`, { replace: true });
    } else {
      navigate("/login", { replace: true });
    }
  }, [isAuthenticated, navigate, redirectTo]);

  return (
    <Container component="main" maxWidth="xs" className="container">
      <CssBaseline />
      <Box className="box">
        <Typography component="h1" variant="h5" className="title">
          Sign In
        </Typography>
        <Box component="form" onSubmit={handleLogin} className="form">
          <TextField
            margin="normal"
            fullWidth
            id="email"
            placeholder="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            value={authInfo.email}
            onChange={handleChange}
            onBlur={handleBlur}
            error={!!formErrors.email}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <AccountCircle />
                </InputAdornment>
              ),
            }}
          />
          {formErrors.email && (
            <Typography color="error">{formErrors.email}</Typography>
          )}
          <TextField
            margin="normal"
            fullWidth
            name="password"
            placeholder="Password"
            type={showPassword ? "text" : "password"}
            id="password"
            autoComplete="current-password"
            value={authInfo.password}
            onChange={handleChange}
            onBlur={handleBlur}
            error={!!formErrors.password}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Lock />
                </InputAdornment>
              ),
            }}
          />
          {formErrors.password && (
            <Typography color="error">{formErrors.password}</Typography>
          )}
          <FormControlLabel
            control={
              <Checkbox
                value={showPassword}
                color="primary"
                onChange={() => setShowPassword(!showPassword)}
              />
            }
            label="Show Password"
          />
          {error && <Typography color="error">{error}</Typography>}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            className="submit"
            disabled={loading || !authInfo.email || !authInfo.password}
          >
            {loading ? "Logging in..." : "Sign In"}
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default Login;
