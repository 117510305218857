import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CircularProgress } from "@mui/material";
import CustomTable from "../../components/CustomTable";
import BusinessProfileFilters from "./BusinessProfileFilters";
import BusinessProfileActions from "./BusinessProfileActions";
import {
  fetchBusinessProfilesRequest,
  markBusinessProfileCreated,
} from "../../api/businessProfiles/businessProfilesSlice";
import TopBar from "../../components/TopBar";
import LeadingActions from "./LeadingActions";
import useDocumentTitle from "../../hooks/useDocumentTitle";

function BusinessProfileList({ config }) {
  useDocumentTitle("Business Profile List");
  const dispatch = useDispatch();
  const { businessProfiles, currentPage, totalDocuments, loading, error } =
    useSelector((state) => state.businessProfiles);
  const countryCodes = useSelector((state) => state.countryCodes.countryCodes);
  const { filters, title, api, columns, actions, leadingActions } = config;
  const [selectedFilter, setSelectedFilter] = useState(
    filters.length > 0 ? filters[0].options[0].key : "",
  );
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    dispatch(markBusinessProfileCreated(false));
    dispatch(
      fetchBusinessProfilesRequest({ url: api.url, page: 1, limit: 10 }),
    );
  }, [dispatch, api.url]);

  if (loading)
    return (
      <CircularProgress sx={{ display: "block", margin: "auto", mt: 4 }} />
    );
  if (error) return <div>Error: {error}</div>;

  const handleChangePage = (event, newPage) => {
    dispatch(
      fetchBusinessProfilesRequest({
        url: api.url,
        page: newPage + 1,
        limit: 10,
      }),
    );
  };

  const handleChangeRowsPerPage = (event) => {
    const newLimit = parseInt(event.target.value, 10);
    dispatch(
      fetchBusinessProfilesRequest({ url: api.url, page: 1, limit: newLimit }),
    );
  };

  const handleFilterChange = (event) => {
    setSelectedFilter(event.target.value);
    dispatch(
      fetchBusinessProfilesRequest({
        url: api.url,
        page: 1,
        limit: 10,
        search_by: event.target.value,
        search_key: searchTerm,
      }),
    );
  };

  const handleSearchChange = (event) => setSearchTerm(event.target.value);

  const handleSearchKeyPress = (event) => {
    if (event.key === "Enter") {
      dispatch(
        fetchBusinessProfilesRequest({
          url: api.url,
          page: 1,
          limit: 10,
          search_by: selectedFilter,
          search_key: searchTerm,
        }),
      );
    }
  };

  const getCountryName = (code) => {
    const country = countryCodes.find((country) => country.Code === code);
    return country ? country.Country : code;
  };
  return (
    <>
      <TopBar title={title}>
        <LeadingActions actions={leadingActions} />
      </TopBar>
      <BusinessProfileFilters
        filters={filters}
        selectedFilter={selectedFilter}
        onFilterChange={handleFilterChange}
        searchTerm={searchTerm}
        onSearchChange={handleSearchChange}
        onSearchKeyPress={handleSearchKeyPress}
      />
      <CustomTable
        columns={columns}
        filteredData={businessProfiles?.map((profile) => ({
          ...profile,
          country_code: getCountryName(profile.country_code),
        }))}
        currentPage={currentPage}
        totalCount={totalDocuments}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        ActionsComponent={BusinessProfileActions}
        childComponentProps={{ actions }}
      />
    </>
  );
}

export default BusinessProfileList;
