import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  debitNotes: [],
  currentPage: 1,
  selectedDebitNote: null,
  totalPages: 1,
  totalDocuments: 0,
  isDebitNoteCreated: false,
  loading: false,
  error: null,
};

const debitNotesSlice = createSlice({
  name: "debitNotes",
  initialState,
  reducers: {
    fetchDebitNotesRequest(state) {
      state.loading = true;
      state.error = null;
    },
    fetchDebitNotesSuccess(state, action) {
      state.loading = false;
      state.debitNotes = action.payload.debit_notes;
      state.currentPage = action.payload.currentPage;
      state.totalPages = action.payload.totalPages;
      state.totalDocuments = action.payload.totalDocuments;
    },
    fetchDebitNotesFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    fetchDebitNoteById(state, action) {
      state.loading = true;
      state.error = null;
    },
    fetchDebitNoteByIdSuccess(state, action) {
      state.loading = false;
      state.selectedDebitNote = action.payload;
    },
    fetchDebitNoteByIdFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    createDebitNoteRequest(state) {
      state.loading = true;
      state.error = null;
    },
    createDebitNoteSuccess(state, action) {
      state.loading = false;
      state.error = null;
      state.isDebitNoteCreated = true;
    },
    createDebitNoteFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    markDebitNoteCreated(state, action) {
      state.isDebitNoteCreated = false;
    },
  },
});

export const {
  fetchDebitNotesRequest,
  fetchDebitNotesSuccess,
  fetchDebitNotesFailure,
  fetchDebitNoteById,
  fetchDebitNoteByIdSuccess,
  fetchDebitNoteByIdFailure,
  createDebitNoteRequest,
  createDebitNoteSuccess,
  createDebitNoteFailure,
  markDebitNoteCreated,
} = debitNotesSlice.actions;

export default debitNotesSlice.reducer;
