import React, { useState } from "react";
import { IconButton, Menu } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { GoToDetailsAction } from "./actions/GoToDetailsAction";

const SupplierActions = ({ actions: configuredActions, row }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getActions = (actions) => {
    return actions.map((action) => {
      switch (action.action) {
        case "details":
          return (
            <GoToDetailsAction
              key={action.action}
              action={action}
              supplier={row}
            />
          );

        default:
          console.log("Unknown action");
          return null;
      }
    });
  };

  return (
    <>
      <IconButton onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {getActions(configuredActions)}
      </Menu>
    </>
  );
};

export default SupplierActions;
