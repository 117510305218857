import { takeLatest, call, put } from "redux-saga/effects";
import {
  fetchCreditNotesRequest,
  fetchCreditNotesSuccess,
  fetchCreditNotesFailure,
  fetchCreditNoteById,
  fetchCreditNoteByIdSuccess,
  fetchCreditNoteByIdFailure,
  createCreditNoteRequest,
  createCreditNoteFailure,
  createCreditNoteSuccess,
} from "./creditNotesSlice";
import { apiClient } from "../../utils/axios";

function* fetchCreditNotesSaga(action) {
  try {
    const { url, page, limit, search_key, search_by } = action.payload;
    const params = {
      page,
      limit,
      ...(search_key && search_by && { search_key, search_by }),
    };
    const response = yield call(apiClient.get, url, { params });
    yield put(fetchCreditNotesSuccess(response.data));
  } catch (error) {
    yield put(fetchCreditNotesFailure(error.message));
  }
}

function* fetchCreditNoteByIdSaga(action) {
  try {
    const response = yield call(
      apiClient.get,
      `/credit-notes/${action.payload}`,
    );
    yield put(fetchCreditNoteByIdSuccess(response.data));
  } catch (error) {
    yield put(fetchCreditNoteByIdFailure(error.message));
  }
}

function* createCreditNoteSaga(action) {
  try {
    const response = yield call(
      apiClient.post,
      "/credit-notes",
      action.payload,
    );
    yield put(createCreditNoteSuccess(response.data));
  } catch (error) {
    yield put(createCreditNoteFailure(error.message));
  }
}

function* creditNotesSaga() {
  yield takeLatest(fetchCreditNotesRequest.type, fetchCreditNotesSaga);
  yield takeLatest(fetchCreditNoteById.type, fetchCreditNoteByIdSaga);
  yield takeLatest(createCreditNoteRequest.type, createCreditNoteSaga);
}

export default creditNotesSaga;
