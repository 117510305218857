import { takeLatest, call, put } from "redux-saga/effects";
import { apiClient } from "../../utils/axios";
import {
  createCustomerFailure,
  createCustomerRequest,
  createCustomerSuccess,
  fetchCustomerById,
  fetchCustomerByIdFailure,
  fetchCustomerByIdSuccess,
  fetchCustomersFailure,
  fetchCustomersRequest,
  fetchCustomersSuccess,
} from "./customersSlice";

function* fetchCustomersSaga(action) {
  try {
    const { url, page, limit, search_key, search_by } = action.payload;
    const params = {
      page,
      limit,
      ...(search_key && search_by && { search_key, search_by }),
    };
    const response = yield call(apiClient.get, url, { params });
    yield put(fetchCustomersSuccess(response.data));
  } catch (error) {
    yield put(fetchCustomersFailure(error.message));
  }
}

function* fetchCustomerByIdSaga(action) {
  try {
    const response = yield call(apiClient.get, `/customers/${action.payload}`);
    yield put(fetchCustomerByIdSuccess(response.data));
  } catch (error) {
    yield put(fetchCustomerByIdFailure(error.message));
  }
}

function* createCustomerSaga(action) {
  try {
    const response = yield call(apiClient.post, "/customers", action.payload);
    yield put(createCustomerSuccess(response.data));
  } catch (error) {
    yield put(
      createCustomerFailure(JSON.stringify(error.response.data.errors)),
    );
  }
}

function* customersSaga() {
  yield takeLatest(fetchCustomersRequest.type, fetchCustomersSaga);
  yield takeLatest(fetchCustomerById.type, fetchCustomerByIdSaga);
  yield takeLatest(createCustomerRequest.type, createCustomerSaga);
}

export default customersSaga;
