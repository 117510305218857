import { useTheme } from "@mui/material";

const SummaryCard = ({ rows, document }) => {
  const theme = useTheme();
  const totalDiscount =
    document?.total_item_discount - document?.header_discount || 0;
  return (
    <div
      className="summary-details-container"
      style={{ padding: theme.spacing(2) }}
    >
      {document &&
        rows.map((row, index) => (
          <div
            className="summary-item"
            key={index}
            style={{ marginBottom: theme.spacing(1.5) }}
          >
            <span
              className="summary-label"
              style={{
                fontWeight: theme.typography.fontWeightBold,
                marginRight: theme.spacing(1),
              }}
            >
              {row.label}:
            </span>
            <span
              className="summary-currency"
              style={{ marginRight: theme.spacing(1) }}
            >
              {document?.document_currency_code}
            </span>
            {row.value === "total_item_discount" ? (
              <span className="summary-value">{totalDiscount?.toFixed(2)}</span>
            ) : (
              <span className="summary-value">
                {(document[row.value] || 0)?.toFixed(2)}
              </span>
            )}
          </div>
        ))}
    </div>
  );
};

export default SummaryCard;
