import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  msicCodes: [],
  loading: false,
  error: null,
};

const msicCodesSlice = createSlice({
  name: "msicCodes",
  initialState,
  reducers: {
    fetchMsicCodesRequest(state) {
      state.loading = true;
      state.error = null;
    },
    fetchMsicCodesSuccess(state, action) {
      state.loading = false;
      state.msicCodes = action.payload;
    },
    fetchMsicCodesFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  fetchMsicCodesRequest,
  fetchMsicCodesSuccess,
  fetchMsicCodesFailure,
} = msicCodesSlice.actions;

export default msicCodesSlice.reducer;
