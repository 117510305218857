import React from "react";
import { InputLabel, Grid, TextField, Autocomplete } from "@mui/material";

const CustomSelectInput = ({
  field,
  formData,
  handleChange,
  errors,
  options,
  labelKey,
  valueKey,
  disabled,
  readOnly,
}) => {
  return (
    <Grid
      item
      xs={12}
      sm={field.size ? field.size : 6}
      key={field.key}
      sx={{ mb: 1.2 }}
    >
      <InputLabel sx={{ color: "black", fontSize: "0.875rem", mb: 0.5 }}>
        {field.label}:{" "}
        {field.required && <span style={{ color: "red" }}>*</span>}
      </InputLabel>
      <Autocomplete
        options={options}
        getOptionLabel={(option) => option[labelKey]}
        isOptionEqualToValue={(option, value) =>
          option[valueKey] === value[valueKey]
        }
        size="small"
        disabled={disabled}
        value={
          options.find((option) => option[valueKey] === formData[field.key]) ||
          null
        }
        onChange={(event, newValue) => {
          handleChange({
            target: {
              name: field.key,
              value: newValue ? newValue[valueKey] : "",
            },
          });
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            name={field.key}
            error={!!errors[field.key]}
            variant="outlined"
            size="small"
            sx={{ fontSize: "0.875rem" }}
            InputProps={{
              ...params.InputProps,
              readOnly: readOnly,
            }}
          />
        )}
        sx={{ fontSize: "0.875rem" }}
      />
    </Grid>
  );
};

export default CustomSelectInput;
