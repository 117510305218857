import { takeLatest, call, put } from "redux-saga/effects";
import {
  fetchRefundsRequest,
  fetchRefundsSuccess,
  fetchRefundsFailure,
  fetchRefundById,
  fetchRefundByIdSuccess,
  fetchRefundByIdFailure,
  createRefundRequest,
  createRefundFailure,
  createRefundSuccess,
} from "./refundsSlice";
import { apiClient } from "../../utils/axios";

function* fetchRefundsSaga(action) {
  try {
    const { url, page, limit, search_key, search_by } = action.payload;
    const params = {
      page,
      limit,
      ...(search_key && search_by && { search_key, search_by }),
    };
    const response = yield call(apiClient.get, url, { params });
    yield put(fetchRefundsSuccess(response.data));
  } catch (error) {
    yield put(fetchRefundsFailure(error.message));
  }
}

function* fetchRefundByIdSaga(action) {
  try {
    const response = yield call(apiClient.get, `/refunds/${action.payload}`);
    yield put(fetchRefundByIdSuccess(response.data));
  } catch (error) {
    yield put(fetchRefundByIdFailure(error.message));
  }
}

function* createRefundSaga(action) {
  try {
    const response = yield call(apiClient.post, "/refunds", action.payload);
    yield put(createRefundSuccess(response.data));
  } catch (error) {
    yield put(createRefundFailure(error.message));
  }
}

function* refundsSaga() {
  yield takeLatest(fetchRefundsRequest.type, fetchRefundsSaga);
  yield takeLatest(fetchRefundById.type, fetchRefundByIdSaga);
  yield takeLatest(createRefundRequest.type, createRefundSaga);
}

export default refundsSaga;
