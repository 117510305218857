import React from "react";
import { Grid, InputLabel, TextField } from "@mui/material";

const CustomDateInput = ({
  field,
  formData,
  handleChange,
  errors,
  disabled,
  readOnly,
}) => (
  <Grid item xs={12} sm={6}>
    <InputLabel sx={{ color: "black", mb: -1.5, fontSize: "0.875rem" }}>
      {field.label ? `${field.label}: ` : ""}{" "}
      {field.required && <span style={{ color: "red" }}>*</span>}
    </InputLabel>
    <TextField
      placeholder={field.label}
      name={field.key}
      value={formData[field.key]}
      onChange={handleChange}
      fullWidth
      margin="normal"
      error={!!errors[field.key]}
      size="small"
      type="date"
      InputProps={{
        sx: { fontSize: "0.875rem" },
        color: "success",
      }}
      inputProps={{ readOnly: readOnly }}
      disabled={disabled}
    />
  </Grid>
);

export default CustomDateInput;
