import { takeLatest, call, put } from "redux-saga/effects";
import { apiClient } from "../../utils/axios";
import {
  fetchBusinessProfileById,
  fetchBusinessProfileByIdFailure,
  fetchBusinessProfileByIdSuccess,
  fetchBusinessProfilesFailure,
  fetchBusinessProfilesRequest,
  fetchBusinessProfilesSuccess,
  createBusinessProfileRequest,
  createBusinessProfileSuccess,
  createBusinessProfileFailure,
} from "./businessProfilesSlice";

function* fetchBusinessProfilesSaga(action) {
  try {
    const { url, page, limit, search_key, search_by } = action.payload;
    const params = {
      page,
      limit,
      ...(search_key && search_by && { search_key, search_by }),
    };
    const response = yield call(apiClient.get, url, { params });
    yield put(fetchBusinessProfilesSuccess(response.data));
  } catch (error) {
    yield put(fetchBusinessProfilesFailure(error.message));
  }
}

function* fetchBusinessProfileByIdSaga(action) {
  try {
    const response = yield call(
      apiClient.get,
      `/business-profiles/${action.payload}`,
    );
    yield put(fetchBusinessProfileByIdSuccess(response.data));
  } catch (error) {
    yield put(fetchBusinessProfileByIdFailure(error.message));
  }
}

function* createBusinessProfileSaga(action) {
  try {
    const response = yield call(
      apiClient.post,
      "/business-profiles",
      action.payload,
    );
    yield put(createBusinessProfileSuccess(response.data));
  } catch (error) {
    yield put(
      createBusinessProfileFailure(JSON.stringify(error.response.data.errors)),
    );
  }
}

function* businessProfilesSaga() {
  yield takeLatest(
    fetchBusinessProfilesRequest.type,
    fetchBusinessProfilesSaga,
  );
  yield takeLatest(fetchBusinessProfileById.type, fetchBusinessProfileByIdSaga);
  yield takeLatest(
    createBusinessProfileRequest.type,
    createBusinessProfileSaga,
  );
}

export default businessProfilesSaga;
