import { createTheme } from "@mui/material/styles";

const lightTheme = createTheme({
  palette: {
    primary: {
      main: "#0d2e4c",
    },
    secondary: {
      main: "#2a3aff",
    },
    secondaryBackground: {
      main: "#000e1c",
    },
    background: {
      default: "#f5f5f5",
      paper: "#f5f5f5",
    },
    text: {
      primary: "#000",
      secondary: "#2a3aff",
    },
    sidebar: {
      main: "#B2C4FA",
    },
  },
  components: {
    MuiButton: {
      variants: [
        {
          props: { variant: "contained" },
          style: {
            backgroundColor: "#2a3aff",
            color: "#ffffff",
          },
        },
        {
          props: { variant: "outlined" },
          style: {
            borderColor: "#2a3aff",
            color: "#2a3aff",
          },
        },
      ],
    },
    MuiCard: {
      styleOverrides: {
        root: {
          padding: "16px",
          border: "1px solid #e0e0e0",
          borderRadius: "8px",
          marginBottom: "20px",
          backgroundColor: "#fff",
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          padding: "4px",
          paddingLeft: "12px",
          border: "1px solid #E9EBFF",
        },
        head: {
          color: "#2a3aff",
          backgroundColor: "#f5f5f5",
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          boxShadow: "0 3px 6px 0 rgba(0, 0, 0, .16)",
        },
      },
    },
    MuiBox: {
      styleOverrides: {
        root: {
          display: "flex",
          alignItems: "center",
          gap: "8px",
          flexWrap: "wrap",
          marginTop: "-24px",
          "@media (max-width: 600px)": {
            flexDirection: "row",
            gap: "4px",
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          boxShadow: "none",
          overflowY: "auto",
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        h6: {
          fontWeight: "bold",
        },
        subtitle2: {
          color: "gray",
        },
        title: {
          fontSize: "1rem",
        },
      },
    },
  },
});

export default lightTheme;
