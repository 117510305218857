import axios from "axios";
import store from "../store";
import { logout } from "../api/auth/authSlice";
import { showSnackbar } from "../api/snackbarSlice"; // Assume you have a snackbar slice to manage global snackbar

const apiClient = axios.create({
  baseURL: `${process.env.REACT_APP_API_BASE_URL}/api`,
  headers: {
    "Content-Type": "application/json",
  },
});

const authApiClient = axios.create({
  baseURL: `${process.env.REACT_APP_API_BASE_URL}/auth`,
});

apiClient.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("AUTH_TOKEN");
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error),
);

apiClient.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response) {
      const { status, data } = error.response;

      if (status === 401) {
        store.dispatch(logout());
        store.dispatch(
          showSnackbar({
            message: "Session expired. Please log in again.",
            severity: "error",
          }),
        );
        window.location.href = "/login";
      } else if (status === 400) {
        store.dispatch(
          showSnackbar({
            message: JSON.stringify(data?.errors),
            severity: "error",
          }),
        );
      }
    } else {
      store.dispatch(
        showSnackbar({
          message: "An unexpected error occurred",
          severity: "error",
        }),
      );
    }
    return Promise.reject(error);
  },
);

export { apiClient, authApiClient };
