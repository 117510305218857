import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  itemCodes: [],
  loading: false,
  error: null,
};

const itemCodesSlice = createSlice({
  name: "itemCodes",
  initialState,
  reducers: {
    fetchItemCodesRequest(state) {
      state.loading = true;
      state.error = null;
    },
    fetchItemCodesSuccess(state, action) {
      state.loading = false;
      state.itemCodes = action.payload;
    },
    fetchItemCodesFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  fetchItemCodesRequest,
  fetchItemCodesSuccess,
  fetchItemCodesFailure,
} = itemCodesSlice.actions;

export default itemCodesSlice.reducer;
