export const MYSConfig = {
  layout: {
    header: {
      title: "Marmin",
    },
    sidebar: {
      "business-profiles": {
        name: "business-profiles",
      },
      documents: {
        name: "Documents",
        features: ["invoices", "credit-notes", "Refunds", "debit-notes"],
      },
      customers: {
        name: "Customers",
      },
      suppliers: {
        name: "Suppliers",
      },
      "self-billed-documents": {
        name: "Self Billed Documents",
        features: [
          "self-billed-invoices",
          "self-billed-credit-notes",
          "self-billed-refunds",
          "self-billed-debit-notes",
        ],
      },
    },
    "business-profiles": {
      name: "Business Profiles",
      list: {
        title: "Business Profiles",
        filters: [
          {
            type: "search",
            options: [
              {
                key: "name",
                placeholder: "Name",
              },
              {
                key: "email",
                placeholder: "Email",
              },
            ],
          },
        ],
        api: {
          url: "/business-profiles",
          method: "GET",
        },
        columns: [
          {
            columnName: "Name / Company Name",
            mappingField: "name",
          },
          {
            columnName: "Contact Info",
            mappingField: "email",
          },
          {
            columnName: "Registration Number",
            mappingField: "registration_number",
          },
          {
            columnName: "Country",
            mappingField: "country_code",
          },
          {
            columnName: "Actions",
            mappingField: "actions",
          },
        ],
        actions: [
          {
            label: "Details",
            action: "details",
            target: "/business-profiles/:id",
          },
        ],
        leadingActions: [
          {
            label: "+ Add New Business",
            action: "create",
            target: "/business-profiles/create",
          },
        ],
      },
      create: {
        title: "Add Business Profile",
        actions: [
          {
            label: "Cancel",
            action: "cancel",
            target: "/business-profiles",
            variant: "outlined",
          },
          {
            label: "Save",
            action: "save",
            target: "/business-profiles",
            variant: "contained",
          },
        ],
        api: {
          url: "/business-profiles",
          method: "POST",
        },
        businessFormData: [
          {
            value: "",
            label: "Name",
            key: "name",
            required: true,
            type: "StringInput",
          },
          {
            value: "",
            label: "Company Name",
            key: "company_name",
            required: false,
            type: "StringInput",
          },
          {
            value: "",
            label: "Company Name in Local Language",
            key: "company_name_in_local",
            required: false,
            type: "StringInput",
          },
          {
            value: "",
            label: "Email",
            key: "email",
            required: true,
            type: "StringInput",
          },
        ],
        addressFormData: [
          {
            value: "",
            label: "Address 1",
            key: "address1",
            type: "StringInput",
            required: true,
          },
          {
            value: "",
            label: "Address 2",
            key: "address2",
            type: "StringInput",
            required: false,
          },
          {
            value: "",
            label: "Address 3",
            key: "address3",
            type: "StringInput",
            required: false,
          },
          {
            value: "",
            label: "City",
            key: "city",
            type: "StringInput",
            required: true,
          },
          {
            value: "",
            label: "Country",
            key: "country_code",
            type: "DynamicSelectInput",
            options: {
              labelKey: "Country",
              valueKey: "Code",
            },
            required: true,
          },
          {
            value: "",
            label: "State",
            key: "state",

            type: "DynamicSelectInput",
            options: {
              labelKey: "State",
              valueKey: "Code",
            },
            required: true,
          },
          {
            value: "",
            label: "Postal Code",
            key: "postal_code",
            type: "StringInput",
            required: false,
          },
          {
            value: "",
            label: "Phone",
            key: "phone",
            type: "StringInput",
            required: true,
          },
        ],
        businessIdentifiersFormData: [
          {
            value: "",
            label: "Registration Type",
            key: "registration_type",
            type: "StaticSelectInput",
            options: [
              {
                label: "BRN",
                value: "BRN",
              },
              {
                label: "PASSPORT",
                value: "PASSPORT",
              },
              {
                label: "ARMY",
                value: "ARMY",
              },
              {
                label: "NRIC",
                value: "NRIC",
              },
            ],
            required: true,
          },
          {
            value: "",
            label: "Registration Number",
            key: "registration_number",
            type: "StringInput",
            required: true,
          },
          {
            value: "",
            label: "TIN",
            key: "tin",
            type: "StringInput",
            required: true,
          },
          {
            value: "NA",
            label: "SST Registration Number",
            key: "sst_registration_number",
            type: "StringInput",
            required: false,
          },
          {
            value: "NA",
            label: "Tourism Tax Registration Number",
            key: "tourism_tax_registration_number",
            type: "StringInput",
            required: false,
          },
          {
            value: "",
            label: "MSIC Code",
            key: "msic_code",
            type: "DynamicSelectInput",
            options: {
              labelKey: "Description",
              valueKey: "Code",
            },
            required: true,
          },
        ],
      },
      details: {
        title: "Business Profile Details",
        api: {
          url: "/business-profiles/:id",
          method: "GET",
        },
        businessProfileSection: [
          {
            label: "Name",
            key: "name",
          },
          {
            label: "Company Name",
            key: "name",
          },
          {
            label: "Email",
            key: "email",
          },
          {
            label: "Phone Number",
            key: "phone",
          },
          {
            label: "Company Name in Local Language",
            key: "company_name_in_local",
          },
        ],
        addressSection: [
          {
            label: "Address street",
            key: "address1",
          },
          {
            label: "Address street(Additional)",
            key: "address2",
          },
          {
            label: "City",
            key: "city",
          },
          {
            label: "State",
            key: "state",
          },
          {
            label: "Country",
            key: "country_code",
          },
          {
            label: "Postal Code",
            key: "postal_code",
          },
        ],
        identifiersSection: [
          {
            label: "TIN",
            key: "tin",
          },
          {
            label: "Company Registration Type",
            key: "registration_type",
          },
          {
            label: "Company Registration Number",
            key: "registration_number",
          },
          {
            label: "SST Registration Number",
            key: "sst_registration_number",
          },
          {
            label: "MSIC Description",
            key: "msic_code",
          },
          {
            label: "Tourism Tax Reg No",
            key: "tourism_tax_registration_number",
          },
        ],
        actions: [
          {
            label: "Back",
            action: "back",
            target: "/business-profiles",
          },
        ],
      },
    },
    invoices: {
      name: "Invoices",
      list: {
        title: "Invoices",
        filters: [
          {
            type: "search",
            options: [
              {
                key: "document_number",
                placeholder: "Invoice Number",
              },
            ],
          },
        ],
        documentStatus: {
          key: "irbm_response.status",
        },
        api: {
          url: "/invoices?document_type=01",
          method: "GET",
        },
        columns: [
          {
            columnName: "Invoice No",
            mappingField: "document_number",
          },
          {
            columnName: "Customer Info",
            mappingField: "billed_by.name",
          },
          {
            columnName: "Invoice Date",
            mappingField: "document_date",
          },
          {
            columnName: "Amount",
            mappingField: "grand_total",
          },
          {
            columnName: "Actions",
            mappingField: "actions",
          },
        ],
        actions: [
          {
            label: "Details",
            action: "details",
            target: "/invoices/:id",
          },
          {
            label: "Download PDF",
            action: "download-pdf",
          },
          {
            label: "Download XML",
            action: "download-xml",
            key: "irbm_response.reported_invoice",
          },
        ],
        leadingActions: [
          {
            label: "+ Create Invoice",
            action: "create",
            target: "/invoices/create",
          },
        ],
      },
      create: {
        title: "Add Invoice",
        component: "MYSInvoiceFormComponent",
        actions: [
          {
            label: "Cancel",
            action: "cancel",
            target: "/invoices",
            variant: "outlined",
          },
          {
            label: "Save",
            action: "save",
            target: "/invoices",
            variant: "contained",
          },
        ],
      },
      details: {
        title: "Invoice Details",
        api: {
          url: "/invoice/:id",
          method: "GET",
        },
        irbmSection: [
          {
            label: "IRBM Status",
            key: "irbm_response.status",
          },
          {
            label: "Document UUID",
            key: "irbm_response.document_id",
          },
          {
            label: "Validation Steps",
            key: "irbm_response.validation_results.validation_steps",
          },
        ],
        invoiceSection: [
          {
            label: "Invoice No",
            key: "document_number",
          },
          {
            label: "Invoice Date",
            key: "document_date",
          },
          {
            label: "Invoice Type",
            key: "document_type",
          },
          {
            label: "Payment Terms",
            key: "payment_terms",
          },
          {
            label: "Currency",
            key: "document_currency_code",
          },
        ],
        itemSection: [
          {
            columnName: "Item Code",
            mappingField: "item_code",
            isNumeric: false,
          },
          {
            columnName: "Item / Service Description",
            mappingField: "description",
            isNumeric: false,
          },
          {
            columnName: "Quantity",
            mappingField: "quantity",
            isNumeric: true,
          },
          {
            columnName: "Unit Rate",
            mappingField: "unit_rate",
            isNumeric: true,
          },
          {
            columnName: "Discount",
            mappingField: "discount_amount",
            isNumeric: true,
          },
          {
            columnName: "Tax",
            mappingField: "tax",
            isNumeric: true,
          },
          {
            columnName: "Tax Code",
            mappingField: "tax_category_code",
            isNumeric: false,
          },
          {
            columnName: "Total (incl. of Tax)",
            mappingField: "total_amount",
            isNumeric: true,
          },
        ],
        actions: [
          {
            label: "Back",
            action: "back",
            target: "/invoices",
          },
        ],
        sellerSection: {
          title: "Billed By",
          subtitle: "Vendor Info",
          fields: [
            {
              label: "Contact Person",
              key: "billed_by.name",
            },
            {
              label: "Company Name",
              key: "billed_by.name",
            },
            {
              label: "Address",
              key: "billed_by",
            },
            {
              label: "Country",
              key: "billed_by.country_code",
            },
            {
              label: "Phone",
              key: "billed_by.phone",
            },
            {
              label: "Email",
              key: "billed_by.email",
            },
            {
              label: "CR",
              key: "billed_by.registration_number",
            },
            {
              label: "TIN",
              key: "billed_by.tin",
            },
            {
              label: "VAT/GST No",
              key: "billed_by.sst_registration_number",
            },
          ],
        },
        buyerSection: {
          title: "Billed To",
          subtitle: "Customer Info",
          fields: [
            {
              label: "Contact Person",
              key: "billed_to.name",
            },
            {
              label: "Company Name",
              key: "billed_to.name",
            },
            {
              label: "Address",
              key: "billed_to",
            },
            {
              label: "Country",
              key: "billed_to.country_code",
            },
            {
              label: "Phone",
              key: "billed_to.phone",
            },
            {
              label: "Email",
              key: "billed_to.email",
            },
            {
              label: "CR",
              key: "billed_to.registration_number",
            },
            {
              label: "TIN",
              key: "billed_to.tin",
            },
            {
              label: "VAT/GST No",
              key: "billed_to.sst_registration_number",
            },
          ],
        },
        paymentSection: {
          title: "Payment Info",
          subtitle: "Bank Details",
          fields: [
            {
              label: "Bank Country",
              key: "payment.bank_country",
            },
            {
              label: "Account Number",
              key: "payment.ac_no",
            },
            {
              label: "IBAN",
              key: "payment.iban",
            },
            {
              label: "Bank Name",
              key: "payment.bank_name",
            },
            {
              label: "SWIFT Code",
              key: "payment.swift_code",
            },
            {
              label: "Beneficiary Name",
              key: "payment.beneficiary_name",
            },
          ],
        },
        summarySection: [
          {
            label: "Sub Total",
            value: "sub_total",
          },
          {
            label: "Total Discount Amount",
            value: "total_item_discount",
          },
          {
            label: "Total Taxable Amount",
            value: "total_excluding_tax",
          },
          {
            label: "Total Tax",
            value: "total_tax_amount",
          },
          {
            label: "Grand Total",
            value: "grand_total",
          },
        ],
        grandTotalInWords: {
          label: "",
        },
      },
    },
    "credit-notes": {
      name: "Credit Notes",
      list: {
        title: "Credit Notes",
        filters: [
          {
            type: "search",
            options: [
              {
                key: "document_number",
                placeholder: "Credit Note Number",
              },
              {
                key: "invoice_number",
                placeholder: "Invoice Number",
              },
            ],
          },
        ],
        documentStatus: {
          key: "irbm_response.status",
        },
        api: {
          url: "/credit-notes?document_type=02",
          method: "GET",
        },
        columns: [
          {
            columnName: "Credit Note No",
            mappingField: "document_number",
          },
          {
            columnName: "Invoice No",
            mappingField: "invoice_number",
          },
          {
            columnName: "Customer Info",
            mappingField: "billed_by.name",
          },
          {
            columnName: "Credit Note Date",
            mappingField: "document_date",
          },
          {
            columnName: "Amount",
            mappingField: "grand_total",
          },
          {
            columnName: "Actions",
            mappingField: "actions",
          },
        ],
        actions: [
          {
            label: "Details",
            action: "details",
            target: "/credit-notes/:id",
          },
          {
            label: "Download PDF",
            action: "download-pdf",
          },
          {
            label: "Download XML",
            action: "download-xml",
            key: "irbm_response.reported_invoice",
          },
        ],
        leadingActions: [
          {
            label: "+ Raise Credit Note",
            action: "create",
            target: "/credit-notes/create",
          },
        ],
      },
      create: {
        title: "Add Credit Note",
        component: "MYSCreditNoteFormComponent",
        actions: [
          {
            label: "Cancel",
            action: "cancel",
            target: "/credit-notes",
            variant: "outlined",
          },
          {
            label: "Save",
            action: "save",
            target: "/credit-notes",
            variant: "contained",
          },
        ],
      },
      details: {
        title: "Credit Note Details",
        api: {
          url: "/credit-notes/:id",
          method: "GET",
        },
        irbmSection: [
          {
            label: "IRBM Status",
            key: "irbm_response.status",
          },
          {
            label: "Document UUID",
            key: "irbm_response.document_id",
          },
          {
            label: "Validation Steps",
            key: "irbm_response.validation_results.validation_steps",
          },
        ],
        creditNoteSection: [
          {
            label: "Credit Note No",
            key: "document_number",
          },
          {
            label: "Invoice No",
            key: "invoice_number",
          },
          {
            label: "Credit Note Date",
            key: "document_date",
          },
          {
            label: "Credit Note Type",
            key: "document_type",
          },
          {
            label: "Payment Terms",
            key: "payment_terms",
          },
          {
            label: "Currency",
            key: "document_currency_code",
          },
        ],
        itemSection: [
          {
            columnName: "Item Code",
            mappingField: "item_code",
          },
          {
            columnName: "Item / Service Description",
            mappingField: "description",
          },
          {
            columnName: "Quantity",
            mappingField: "quantity",
            isNumeric: true,
          },
          {
            columnName: "Unit Rate",
            mappingField: "unit_rate",
            isNumeric: true,
          },
          {
            columnName: "Discount",
            mappingField: "discount_amount",
            isNumeric: true,
          },
          {
            columnName: "Tax",
            mappingField: "tax",
            isNumeric: true,
          },
          {
            columnName: "Tax Code",
            mappingField: "tax_category_code",
          },
          {
            columnName: "Amount",
            mappingField: "total_amount",
            isNumeric: true,
          },
        ],
        actions: [
          {
            label: "Back",
            action: "back",
            target: "/credit-notes",
          },
        ],
        sellerSection: {
          title: "Billed By",
          subtitle: "Vendor Info",
          fields: [
            {
              label: "Contact Person",
              key: "billed_by.name",
            },
            {
              label: "Company Name",
              key: "billed_by.name",
            },
            {
              label: "Address",
              key: "billed_by",
            },
            {
              label: "Country",
              key: "billed_by.country_code",
            },
            {
              label: "Phone",
              key: "billed_by.phone",
            },
            {
              label: "Email",
              key: "billed_by.email",
            },
            {
              label: "CR",
              key: "billed_by.registration_number",
            },
            {
              label: "TIN",
              key: "billed_by.tin",
            },
            {
              label: "VAT/GST No",
              key: "billed_by.sst_registration_number",
            },
          ],
        },
        buyerSection: {
          title: "Billed To",
          subtitle: "Customer Info",
          fields: [
            {
              label: "Contact Person",
              key: "billed_to.name",
            },
            {
              label: "Company Name",
              key: "billed_to.name",
            },
            {
              label: "Address",
              key: "billed_to",
            },
            {
              label: "Country",
              key: "billed_to.country_code",
            },
            {
              label: "Phone",
              key: "billed_to.phone",
            },
            {
              label: "Email",
              key: "billed_to.email",
            },
            {
              label: "CR",
              key: "billed_to.registration_number",
            },
            {
              label: "TIN",
              key: "billed_to.tin",
            },
            {
              label: "VAT/GST No",
              key: "billed_to.sst_registration_number",
            },
          ],
        },
        paymentSection: {
          title: "Payment Info",
          subtitle: "Bank Details",
          fields: [
            {
              label: "Bank Country",
              key: "payment.bank_country",
            },
            {
              label: "Account Number",
              key: "payment.ac_no",
            },
            {
              label: "IBAN",
              key: "payment.iban",
            },
            {
              label: "Bank Name",
              key: "payment.bank_name",
            },
            {
              label: "SWIFT Code",
              key: "payment.swift_code",
            },
            {
              label: "Beneficiary Name",
              key: "payment.beneficiary_name",
            },
          ],
        },
        summarySection: [
          {
            label: "Sub Total",
            value: "sub_total",
          },
          {
            label: "Total Discount Amount",
            value: "total_item_discount",
          },
          {
            label: "Total Taxable Amount",
            value: "total_excluding_tax",
          },
          {
            label: "Total Tax",
            value: "total_tax_amount",
          },
          {
            label: "Grand Total",
            value: "grand_total",
          },
        ],
        grandTotalInWords: {
          label: "",
        },
      },
    },
    refunds: {
      name: "Refunds",
      list: {
        title: "Refunds",
        filters: [
          {
            type: "search",
            options: [
              {
                key: "document_number",
                placeholder: "Refund Number",
              },
              {
                key: "invoice_number",
                placeholder: "Invoice Number",
              },
            ],
          },
        ],
        documentStatus: {
          key: "irbm_response.status",
        },
        api: {
          url: "/refunds?document_type=04",
          method: "GET",
        },
        columns: [
          {
            columnName: "Refund No",
            mappingField: "document_number",
          },
          {
            columnName: "Invoice No",
            mappingField: "invoice_number",
          },
          {
            columnName: "Customer Info",
            mappingField: "billed_by.name",
          },
          {
            columnName: "Refund Date",
            mappingField: "document_date",
          },
          {
            columnName: "Amount",
            mappingField: "grand_total",
          },
          {
            columnName: "Actions",
            mappingField: "actions",
          },
        ],
        actions: [
          {
            label: "Details",
            action: "details",
            target: "/refunds/:id",
          },
          {
            label: "Download PDF",
            action: "download-pdf",
          },
          {
            label: "Download XML",
            action: "download-xml",
            key: "irbm_response.reported_invoice",
          },
        ],
        leadingActions: [
          {
            label: "+ Raise Refund",
            action: "create",
            target: "/refunds/create",
          },
        ],
      },
      create: {
        title: "Raise Refund",
        component: "MYSRefundFormComponent",
        actions: [
          {
            label: "Cancel",
            action: "cancel",
            target: "/refunds",
            variant: "outlined",
          },
          {
            label: "Save",
            action: "save",
            target: "/refunds",
            variant: "contained",
          },
        ],
      },
      details: {
        title: "Refund Details",
        api: {
          url: "/refunds/:id",
          method: "GET",
        },
        irbmSection: [
          {
            label: "IRBM Status",
            key: "irbm_response.status",
          },
          {
            label: "Document UUID",
            key: "irbm_response.document_id",
          },
          {
            label: "Validation Steps",
            key: "irbm_response.validation_results.validation_steps",
          },
        ],
        refundSection: [
          {
            label: "Refund No",
            key: "document_number",
          },
          {
            label: "Invoice No",
            key: "invoice_number",
          },
          {
            label: "Refund Date",
            key: "document_date",
          },
          {
            label: "Refund Type",
            key: "document_type",
          },
          {
            label: "Payment Terms",
            key: "payment_terms",
          },
          {
            label: "Currency",
            key: "document_currency_code",
          },
        ],
        itemSection: [
          {
            columnName: "Item Code",
            mappingField: "item_code",
          },
          {
            columnName: "Item / Service Description",
            mappingField: "description",
          },
          {
            columnName: "Quantity",
            mappingField: "quantity",
            isNumeric: true,
          },
          {
            columnName: "Unit Rate",
            mappingField: "unit_rate",
            isNumeric: true,
          },
          {
            columnName: "Discount",
            mappingField: "discount_amount",
            isNumeric: true,
          },
          {
            columnName: "Tax",
            mappingField: "tax",
            isNumeric: true,
          },
          {
            columnName: "Tax Code",
            mappingField: "tax_category_code",
          },
          {
            columnName: "Amount",
            mappingField: "total_amount",
            isNumeric: true,
          },
        ],
        actions: [
          {
            label: "Back",
            action: "back",
            target: "/refunds",
          },
        ],
        sellerSection: {
          title: "Billed By",
          subtitle: "Vendor Info",
          fields: [
            {
              label: "Contact Person",
              key: "billed_by.name",
            },
            {
              label: "Company Name",
              key: "billed_by.name",
            },
            {
              label: "Address",
              key: "billed_by",
            },
            {
              label: "Country",
              key: "billed_by.country_code",
            },
            {
              label: "Phone",
              key: "billed_by.phone",
            },
            {
              label: "Email",
              key: "billed_by.email",
            },
            {
              label: "CR",
              key: "billed_by.registration_number",
            },
            {
              label: "TIN",
              key: "billed_by.tin",
            },
            {
              label: "VAT/GST No",
              key: "billed_by.sst_registration_number",
            },
          ],
        },
        buyerSection: {
          title: "Billed To",
          subtitle: "Customer Info",
          fields: [
            {
              label: "Contact Person",
              key: "billed_to.name",
            },
            {
              label: "Company Name",
              key: "billed_to.name",
            },
            {
              label: "Address",
              key: "billed_to",
            },
            {
              label: "Country",
              key: "billed_to.country_code",
            },
            {
              label: "Phone",
              key: "billed_to.phone",
            },
            {
              label: "Email",
              key: "billed_to.email",
            },
            {
              label: "CR",
              key: "billed_to.registration_number",
            },
            {
              label: "TIN",
              key: "billed_to.tin",
            },
            {
              label: "VAT/GST No",
              key: "billed_to.sst_registration_number",
            },
          ],
        },
        paymentSection: {
          title: "Payment Info",
          subtitle: "Bank Details",
          fields: [
            {
              label: "Bank Country",
              key: "payment.bank_country",
            },
            {
              label: "Account Number",
              key: "payment.ac_no",
            },
            {
              label: "IBAN",
              key: "payment.iban",
            },
            {
              label: "Bank Name",
              key: "payment.bank_name",
            },
            {
              label: "SWIFT Code",
              key: "payment.swift_code",
            },
            {
              label: "Beneficiary Name",
              key: "payment.beneficiary_name",
            },
          ],
        },
        summarySection: [
          {
            label: "Sub Total",
            value: "sub_total",
          },
          {
            label: "Total Discount Amount",
            value: "total_item_discount",
          },
          {
            label: "Total Taxable Amount",
            value: "total_excluding_tax",
          },
          {
            label: "Total Tax",
            value: "total_tax_amount",
          },
          {
            label: "Grand Total",
            value: "grand_total",
          },
        ],
        grandTotalInWords: {
          label: "",
        },
      },
    },
    "debit-notes": {
      name: "Debit Notes",
      list: {
        title: "Debit Notes",
        filters: [
          {
            type: "search",
            options: [
              {
                key: "document_number",
                placeholder: "Debit Note Number",
              },
              {
                key: "invoice_number",
                placeholder: "Invoice Number",
              },
            ],
          },
        ],
        documentStatus: {
          key: "irbm_response.status",
        },
        api: {
          url: "/debit-notes?document_type=03",
          method: "GET",
        },
        columns: [
          {
            columnName: "Debit Note No",
            mappingField: "document_number",
          },
          {
            columnName: "Invoice No",
            mappingField: "invoice_number",
          },
          {
            columnName: "Customer Info",
            mappingField: "billed_by.name",
          },
          {
            columnName: "Debit Note Date",
            mappingField: "document_date",
          },
          {
            columnName: "Amount",
            mappingField: "grand_total",
          },
          {
            columnName: "Actions",
            mappingField: "actions",
          },
        ],
        actions: [
          {
            label: "Details",
            action: "details",
            target: "/debit-notes/:id",
          },
          {
            label: "Download PDF",
            action: "download-pdf",
          },
          {
            label: "Download XML",
            action: "download-xml",
            key: "irbm_response.reported_invoice",
          },
        ],
        leadingActions: [
          {
            label: "+ Raise Debit Note",
            action: "create",
            target: "/debit-notes/create",
          },
        ],
      },
      create: {
        title: "Add Debit Note",
        component: "MYSDebitNoteFormComponent",
        actions: [
          {
            label: "Cancel",
            action: "cancel",
            target: "/debit-notes",
            variant: "outlined",
          },
          {
            label: "Save",
            action: "save",
            target: "/debit-notes",
            variant: "contained",
          },
        ],
      },
      details: {
        title: "Debit Note Details",
        api: {
          url: "/debit-notes/:id",
          method: "GET",
        },
        irbmSection: [
          {
            label: "IRBM Status",
            key: "irbm_response.status",
          },
          {
            label: "Document UUID",
            key: "irbm_response.document_id",
          },
          {
            label: "Validation Steps",
            key: "irbm_response.validation_results.validation_steps",
          },
        ],
        debitNoteSection: [
          {
            label: "Debit Note No",
            key: "document_number",
          },
          {
            label: "Invoice No",
            key: "invoice_number",
          },
          {
            label: "Debit Note Date",
            key: "document_date",
          },
          {
            label: "Debit Note Type",
            key: "document_type",
          },
          {
            label: "Payment Terms",
            key: "payment_terms",
          },
          {
            label: "Currency",
            key: "document_currency_code",
          },
        ],
        itemSection: [
          {
            columnName: "Item Code",
            mappingField: "item_code",
          },
          {
            columnName: "Item / Service Description",
            mappingField: "description",
          },
          {
            columnName: "Quantity",
            mappingField: "quantity",
            isNumeric: true,
          },
          {
            columnName: "Unit Rate",
            mappingField: "unit_rate",
            isNumeric: true,
          },
          {
            columnName: "Discount",
            mappingField: "discount_amount",
            isNumeric: true,
          },
          {
            columnName: "Tax",
            mappingField: "tax",
            isNumeric: true,
          },
          {
            columnName: "Tax Code",
            mappingField: "tax_category_code",
          },
          {
            columnName: "Amount",
            mappingField: "total_amount",
            isNumeric: true,
          },
        ],
        actions: [
          {
            label: "Back",
            action: "back",
            target: "/debit-notes",
          },
        ],
        sellerSection: {
          title: "Billed By",
          subtitle: "Vendor Info",
          fields: [
            {
              label: "Contact Person",
              key: "billed_by.name",
            },
            {
              label: "Company Name",
              key: "billed_by.name",
            },
            {
              label: "Address",
              key: "billed_by",
            },
            {
              label: "Country",
              key: "billed_by.country_code",
            },
            {
              label: "Phone",
              key: "billed_by.phone",
            },
            {
              label: "Email",
              key: "billed_by.email",
            },
            {
              label: "CR",
              key: "billed_by.registration_number",
            },
            {
              label: "TIN",
              key: "billed_by.tin",
            },
            {
              label: "VAT/GST No",
              key: "billed_by.sst_registration_number",
            },
          ],
        },
        buyerSection: {
          title: "Billed To",
          subtitle: "Customer Info",
          fields: [
            {
              label: "Contact Person",
              key: "billed_to.name",
            },
            {
              label: "Company Name",
              key: "billed_to.name",
            },
            {
              label: "Address",
              key: "billed_to",
            },
            {
              label: "Country",
              key: "billed_to.country_code",
            },
            {
              label: "Phone",
              key: "billed_to.phone",
            },
            {
              label: "Email",
              key: "billed_to.email",
            },
            {
              label: "CR",
              key: "billed_to.registration_number",
            },
            {
              label: "TIN",
              key: "billed_to.tin",
            },
            {
              label: "VAT/GST No",
              key: "billed_to.sst_registration_number",
            },
          ],
        },
        paymentSection: {
          title: "Payment Info",
          subtitle: "Bank Details",
          fields: [
            {
              label: "Bank Country",
              key: "payment.bank_country",
            },
            {
              label: "Account Number",
              key: "payment.ac_no",
            },
            {
              label: "IBAN",
              key: "payment.iban",
            },
            {
              label: "Bank Name",
              key: "payment.bank_name",
            },
            {
              label: "SWIFT Code",
              key: "payment.swift_code",
            },
            {
              label: "Beneficiary Name",
              key: "payment.beneficiary_name",
            },
          ],
        },
        summarySection: [
          {
            label: "Sub Total",
            value: "sub_total",
          },
          {
            label: "Total Discount Amount",
            value: "total_item_discount",
          },
          {
            label: "Total Taxable Amount",
            value: "total_excluding_tax",
          },
          {
            label: "Total Tax",
            value: "total_tax_amount",
          },
          {
            label: "Grand Total",
            value: "grand_total",
          },
        ],
        grandTotalInWords: {
          label: "",
        },
      },
    },
    customers: {
      name: "Customers",
      list: {
        title: "Customers",
        filters: [
          {
            type: "search",
            options: [
              {
                key: "name",
                placeholder: "Name",
              },
              {
                key: "email",
                placeholder: "Email",
              },
            ],
          },
        ],
        api: {
          url: "/customers",
          method: "GET",
        },
        columns: [
          {
            columnName: "Name / Company Name",
            mappingField: "name",
          },
          {
            columnName: "Contact Info",
            mappingField: "email",
          },
          {
            columnName: "Registration Number",
            mappingField: "registration_number",
          },
          {
            columnName: "Country",
            mappingField: "country_code",
          },
          {
            columnName: "Actions",
            mappingField: "actions",
          },
        ],
        actions: [
          {
            label: "Details",
            action: "details",
            target: "/customers/:id",
          },
        ],
        leadingActions: [
          {
            label: "+ Add New Customer",
            action: "create",
            target: "/customers/create",
          },
        ],
      },
      create: {
        title: "Add Customer",
        actions: [
          {
            label: "Cancel",
            action: "cancel",
            target: "/customers",
            variant: "outlined",
          },
          {
            label: "Save",
            action: "save",
            target: "/customers",
            variant: "contained",
          },
        ],
        api: {
          url: "/customers",
          method: "POST",
        },
        customerFormData: [
          {
            value: "",
            label: "Name",
            key: "name",
            required: true,
            type: "StringInput",
          },
          {
            value: "",
            label: "Company Name",
            key: "company_name",
            required: false,
            type: "StringInput",
          },
          {
            value: "",
            label: "Company Name in Local Language",
            key: "company_name_in_local",
            required: false,
            type: "StringInput",
          },
          {
            value: "",
            label: "Email",
            key: "email",
            required: true,
            type: "StringInput",
          },
        ],
        addressFormData: [
          {
            value: "",
            label: "Address 1",
            key: "address1",
            type: "StringInput",
            required: true,
          },
          {
            value: "",
            label: "Address 2",
            key: "address2",
            type: "StringInput",
            required: false,
          },
          {
            value: "",
            label: "Address 3",
            key: "address3",
            type: "StringInput",
            required: false,
          },
          {
            value: "",
            label: "City",
            key: "city",
            type: "StringInput",
            required: true,
          },
          {
            value: "",
            label: "Country",
            key: "country_code",
            type: "DynamicSelectInput",
            options: {
              labelKey: "Country",
              valueKey: "Code",
            },
            required: true,
          },
          {
            value: "",
            label: "State",
            key: "state",
            type: "DynamicSelectInput",
            options: {
              labelKey: "State",
              valueKey: "Code",
            },
            required: true,
          },
          {
            value: "",
            label: "Postal Code",
            key: "postal_code",
            type: "StringInput",
            required: false,
          },
          {
            value: "",
            label: "Phone",
            key: "phone",
            type: "StringInput",
            required: true,
          },
        ],
        businessIdentifiersFormData: [
          {
            value: "",
            label: "Registration Type",
            key: "registration_type",
            type: "StaticSelectInput",
            options: [
              {
                label: "BRN",
                value: "BRN",
              },
              {
                label: "PASSPORT",
                value: "PASSPORT",
              },
              {
                label: "ARMY",
                value: "ARMY",
              },
              {
                label: "NRIC",
                value: "NRIC",
              },
            ],
            required: true,
          },
          {
            value: "",
            label: "Registration Number",
            key: "registration_number",
            type: "StringInput",
            required: true,
          },
          {
            value: "",
            label: "TIN",
            key: "tin",
            type: "StringInput",
            required: true,
          },
          {
            value: "NA",
            label: "SST Registration Number",
            key: "sst_registration_number",
            type: "StringInput",
            required: false,
          },
        ],
      },
      details: {
        title: "Customers Details",
        api: {
          url: "/customers/:id",
          method: "GET",
        },
        customerSection: [
          {
            label: "Name",
            key: "name",
          },
          {
            label: "Company Name",
            key: "name",
          },
          {
            label: "Email",
            key: "email",
          },
          {
            label: "Phone Number",
            key: "phone",
          },
          {
            label: "Company Name in Local Language",
            key: "company_name_in_local",
          },
        ],
        addressSection: [
          {
            label: "Address street",
            key: "address1",
          },
          {
            label: "Address street(Additional)",
            key: "address2",
          },
          {
            label: "City",
            key: "city",
          },
          {
            label: "State",
            key: "state",
          },
          {
            label: "Country",
            key: "country_code",
          },
          {
            label: "Postal Code",
            key: "postal_code",
          },
        ],
        identifiersSection: [
          {
            label: "TIN",
            key: "tin",
          },
          {
            label: "Company Registration Type",
            key: "registration_type",
          },
          {
            label: "Company Registration Number",
            key: "registration_number",
          },
          {
            label: "SST Registration Number",
            key: "sst_registration_number",
          },
        ],
        actions: [
          {
            label: "Back",
            action: "back",
            target: "/customers",
          },
        ],
      },
    },
    suppliers: {
      name: "Suppliers",
      list: {
        title: "Suppliers",
        filters: [
          {
            type: "search",
            options: [
              {
                key: "name",
                placeholder: "Name",
              },
              {
                key: "email",
                placeholder: "Email",
              },
            ],
          },
        ],
        api: {
          url: "/suppliers",
          method: "GET",
        },
        columns: [
          {
            columnName: "Name / Company Name",
            mappingField: "name",
          },
          {
            columnName: "Contact Info",
            mappingField: "email",
          },
          {
            columnName: "Registration Number",
            mappingField: "registration_number",
          },
          {
            columnName: "Country",
            mappingField: "country_code",
          },
          {
            columnName: "Actions",
            mappingField: "actions",
          },
        ],
        actions: [
          {
            label: "Details",
            action: "details",
            target: "/suppliers/:id",
          },
        ],
        leadingActions: [
          {
            label: "+ Add New Supplier",
            action: "create",
            target: "/suppliers/create",
          },
        ],
      },
      create: {
        title: "Add Supplier",
        actions: [
          {
            label: "Cancel",
            action: "cancel",
            target: "/suppliers",
            variant: "outlined",
          },
          {
            label: "Save",
            action: "save",
            target: "/suppliers",
            variant: "contained",
          },
        ],
        api: {
          url: "suppliers",
          method: "POST",
        },
        supplierFormData: [
          {
            value: "",
            label: "Name",
            key: "name",
            required: true,
            type: "StringInput",
          },
          {
            value: "",
            label: "Company Name",
            key: "company_name",
            required: false,
            type: "StringInput",
          },
          {
            value: "",
            label: "Company Name in Local Language",
            key: "company_name_in_local",
            required: false,
            type: "StringInput",
          },
          {
            value: "",
            label: "Email",
            key: "email",
            required: true,
            type: "StringInput",
          },
        ],
        addressFormData: [
          {
            value: "",
            label: "Address 1",
            key: "address1",
            type: "StringInput",
            required: true,
          },
          {
            value: "",
            label: "Address 2",
            key: "address2",
            type: "StringInput",
            required: false,
          },
          {
            value: "",
            label: "Address 3",
            key: "address3",
            type: "StringInput",
            required: false,
          },
          {
            value: "",
            label: "City",
            key: "city",
            type: "StringInput",
            required: true,
          },
          {
            value: "",
            label: "Country",
            key: "country_code",
            type: "DynamicSelectInput",
            options: {
              labelKey: "Country",
              valueKey: "Code",
            },
            required: true,
          },
          {
            value: "",
            label: "State",
            key: "state",

            type: "DynamicSelectInput",
            options: {
              labelKey: "State",
              valueKey: "Code",
            },
            required: true,
          },
          {
            value: "",
            label: "Postal Code",
            key: "postal_code",
            type: "StringInput",
            required: false,
          },
          {
            value: "",
            label: "Phone",
            key: "phone",
            type: "StringInput",
            required: true,
          },
        ],
        businessIdentifiersFormData: [
          {
            value: "",
            label: "Registration Type",
            key: "registration_type",
            type: "StaticSelectInput",
            options: [
              {
                label: "BRN",
                value: "BRN",
              },
              {
                label: "PASSPORT",
                value: "PASSPORT",
              },
              {
                label: "ARMY",
                value: "ARMY",
              },
              {
                label: "NRIC",
                value: "NRIC",
              },
            ],
            required: true,
          },
          {
            value: "",
            label: "Registration Number",
            key: "registration_number",
            type: "StringInput",
            required: true,
          },
          {
            value: "",
            label: "TIN",
            key: "tin",
            type: "StringInput",
            required: true,
          },
          {
            value: "NA",
            label: "SST Registration Number",
            key: "sst_registration_number",
            type: "StringInput",
            required: false,
          },
          {
            value: "NA",
            label: "Tourism Tax Registration Number",
            key: "tourism_tax_registration_number",
            type: "StringInput",
            required: false,
          },
          {
            value: "",
            label: "MSIC Code",
            key: "msic_code",
            type: "DynamicSelectInput",
            options: {
              labelKey: "Description",
              valueKey: "Code",
            },
            required: true,
          },
        ],
      },
      details: {
        title: "Supplier Details",
        api: {
          url: "/suppliers/:id",
          method: "GET",
        },
        supplierSection: [
          {
            label: "Name",
            key: "name",
          },
          {
            label: "Company Name",
            key: "name",
          },
          {
            label: "Email",
            key: "email",
          },
          {
            label: "Phone Number",
            key: "phone",
          },
          {
            label: "Company Name in Local Language",
            key: "company_name_in_local",
          },
        ],
        addressSection: [
          {
            label: "Address street",
            key: "address1",
          },
          {
            label: "Address street(Additional)",
            key: "address2",
          },
          {
            label: "City",
            key: "city",
          },
          {
            label: "State",
            key: "state",
          },
          {
            label: "Country",
            key: "country_code",
          },
          {
            label: "Postal Code",
            key: "postal_code",
          },
        ],
        identifiersSection: [
          {
            label: "TIN",
            key: "tin",
          },
          {
            label: "Company Registration Type",
            key: "registration_type",
          },
          {
            label: "Company Registration Number",
            key: "registration_number",
          },
          {
            label: "SST Registration Number",
            key: "sst_registration_number",
          },
          {
            label: "MSIC Description",
            key: "msic_code",
          },
          {
            label: "Tourism Tax Reg No",
            key: "tourism_tax_registration_number",
          },
        ],
        actions: [
          {
            label: "Back",
            action: "back",
            target: "/suppliers",
          },
        ],
      },
    },
    "self-billed-invoices": {
      name: "Self Billed Invoices",
      list: {
        title: "Self Billed Invoices",
        filters: [
          {
            type: "search",
            options: [
              {
                key: "document_number",
                placeholder: "Invoice Number",
              },
            ],
          },
        ],
        documentStatus: {
          key: "irbm_response.status",
        },
        api: {
          url: "/invoices?document_type=11",
          method: "GET",
        },
        columns: [
          {
            columnName: "Invoice No",
            mappingField: "document_number",
          },
          {
            columnName: "Customer Info",
            mappingField: "billed_by.name",
          },
          {
            columnName: "Invoice Date",
            mappingField: "document_date",
          },
          {
            columnName: "Amount",
            mappingField: "grand_total",
          },
          {
            columnName: "Actions",
            mappingField: "actions",
          },
        ],
        actions: [
          {
            label: "Details",
            action: "details",
            target: "/self-billed-invoices/:id",
          },
          {
            label: "Download PDF",
            action: "download-pdf",
          },
          {
            label: "Download XML",
            action: "download-xml",
            key: "irbm_response.reported_invoice",
          },
        ],
        leadingActions: [
          {
            label: "+ Create Self Billed Invoice",
            action: "create",
            target: "/self-billed-invoices/create",
          },
        ],
      },
      create: {
        title: "Add Self Billed Invoice",
        component: "MYSSBInvoiceFormComponent",
        actions: [
          {
            label: "Cancel",
            action: "cancel",
            target: "/self-billed-invoices",
            variant: "outlined",
          },
          {
            label: "Save",
            action: "save",
            target: "/self-billed-invoices",
            variant: "contained",
          },
        ],
      },
      details: {
        title: "Invoice Details",
        api: {
          url: "/self-billed-invoices/:id",
          method: "GET",
        },
        irbmSection: [
          {
            label: "IRBM Status",
            key: "irbm_response.status",
          },
          {
            label: "Document UUID",
            key: "irbm_response.document_id",
          },
          {
            label: "Validation Steps",
            key: "irbm_response.validation_results.validation_steps",
          },
        ],
        invoiceSection: [
          {
            label: "Invoice No",
            key: "document_number",
          },
          {
            label: "Invoice Date",
            key: "document_date",
          },
          {
            label: "Invoice Type",
            key: "document_type",
          },
          {
            label: "Payment Terms",
            key: "payment_terms",
          },
          {
            label: "Currency",
            key: "document_currency_code",
          },
        ],
        itemSection: [
          {
            columnName: "Item Code",
            mappingField: "item_code",
            isNumeric: false,
          },
          {
            columnName: "Item / Service Description",
            mappingField: "description",
            isNumeric: false,
          },
          {
            columnName: "Quantity",
            mappingField: "quantity",
            isNumeric: true,
          },
          {
            columnName: "Unit Rate",
            mappingField: "unit_rate",
            isNumeric: true,
          },
          {
            columnName: "Discount",
            mappingField: "discount_amount",
            isNumeric: true,
          },
          {
            columnName: "Tax",
            mappingField: "tax",
            isNumeric: true,
          },
          {
            columnName: "Tax Code",
            mappingField: "tax_category_code",
            isNumeric: false,
          },
          {
            columnName: "Total (incl. of Tax)",
            mappingField: "total_amount",
            isNumeric: true,
          },
        ],
        actions: [
          {
            label: "Back",
            action: "back",
            target: "/self-billed-invoices",
          },
        ],
        sellerSection: {
          title: "Billed By",
          subtitle: "Vendor Info",
          fields: [
            {
              label: "Contact Person",
              key: "billed_by.name",
            },
            {
              label: "Company Name",
              key: "billed_by.name",
            },
            {
              label: "Address",
              key: "billed_by",
            },
            {
              label: "Country",
              key: "billed_by.country_code",
            },
            {
              label: "Phone",
              key: "billed_by.phone",
            },
            {
              label: "Email",
              key: "billed_by.email",
            },
            {
              label: "CR",
              key: "billed_by.registration_number",
            },
            {
              label: "TIN",
              key: "billed_by.tin",
            },
            {
              label: "VAT/GST No",
              key: "billed_by.sst_registration_number",
            },
          ],
        },
        buyerSection: {
          title: "Billed To",
          subtitle: "Customer Info",
          fields: [
            {
              label: "Contact Person",
              key: "billed_to.name",
            },
            {
              label: "Company Name",
              key: "billed_to.name",
            },
            {
              label: "Address",
              key: "billed_to",
            },
            {
              label: "Country",
              key: "billed_to.country_code",
            },
            {
              label: "Phone",
              key: "billed_to.phone",
            },
            {
              label: "Email",
              key: "billed_to.email",
            },
            {
              label: "CR",
              key: "billed_to.registration_number",
            },
            {
              label: "TIN",
              key: "billed_to.tin",
            },
            {
              label: "VAT/GST No",
              key: "billed_to.sst_registration_number",
            },
          ],
        },
        paymentSection: {
          title: "Payment Info",
          subtitle: "Bank Details",
          fields: [
            {
              label: "Bank Country",
              key: "payment.bank_country",
            },
            {
              label: "Account Number",
              key: "payment.ac_no",
            },
            {
              label: "IBAN",
              key: "payment.iban",
            },
            {
              label: "Bank Name",
              key: "payment.bank_name",
            },
            {
              label: "SWIFT Code",
              key: "payment.swift_code",
            },
            {
              label: "Beneficiary Name",
              key: "payment.beneficiary_name",
            },
          ],
        },
        summarySection: [
          {
            label: "Sub Total",
            value: "sub_total",
          },
          {
            label: "Total Discount Amount",
            value: "total_item_discount",
          },
          {
            label: "Total Taxable Amount",
            value: "total_excluding_tax",
          },
          {
            label: "Total Tax",
            value: "total_tax_amount",
          },
          {
            label: "Grand Total",
            value: "grand_total",
          },
        ],
        grandTotalInWords: {
          label: "",
        },
      },
    },
    "self-billed-credit-notes": {
      name: "Self Billed Credit Notes",
      list: {
        title: "Self Billed Credit Notes",
        filters: [
          {
            type: "search",
            options: [
              {
                key: "document_number",
                placeholder: "Credit Note Number",
              },
              {
                key: "invoice_number",
                placeholder: "Invoice Number",
              },
            ],
          },
        ],
        documentStatus: {
          key: "irbm_response.status",
        },
        api: {
          url: "/credit-notes?document_type=12",
          method: "GET",
        },
        columns: [
          {
            columnName: "Credit Note No",
            mappingField: "document_number",
          },
          {
            columnName: "Invoice No",
            mappingField: "invoice_number",
          },
          {
            columnName: "Customer Info",
            mappingField: "billed_by.name",
          },
          {
            columnName: "Credit Note Date",
            mappingField: "document_date",
          },
          {
            columnName: "Amount",
            mappingField: "grand_total",
          },
          {
            columnName: "Actions",
            mappingField: "actions",
          },
        ],
        actions: [
          {
            label: "Details",
            action: "details",
            target: "/self-billed-credit-notes/:id",
          },
          {
            label: "Download PDF",
            action: "download-pdf",
          },
          {
            label: "Download XML",
            action: "download-xml",
            key: "irbm_response.reported_invoice",
          },
        ],
        leadingActions: [
          {
            label: "+ Raise Self Billed Credit Note",
            action: "create",
            target: "/self-billed-credit-notes/create",
          },
        ],
      },
      create: {
        title: "Add Self Billed Credit Note",
        component: "MYSSBCreditNoteFormComponent",
        actions: [
          {
            label: "Cancel",
            action: "cancel",
            target: "/self-billed-credit-notes",
            variant: "outlined",
          },
          {
            label: "Save",
            action: "save",
            target: "/self-billed-credit-notes",
            variant: "contained",
          },
        ],
      },
      details: {
        title: "Self Billed Credit Note Details",
        api: {
          url: "/self-billed-credit-notes/:id",
          method: "GET",
        },
        irbmSection: [
          {
            label: "IRBM Status",
            key: "irbm_response.status",
          },
          {
            label: "Document UUID",
            key: "irbm_response.document_id",
          },
          {
            label: "Validation Steps",
            key: "irbm_response.validation_results.validation_steps",
          },
        ],
        creditNoteSection: [
          {
            label: "Credit Note No",
            key: "document_number",
          },
          {
            label: "Invoice No",
            key: "invoice_number",
          },
          {
            label: "Credit Note Date",
            key: "document_date",
          },
          {
            label: "Credit Note Type",
            key: "document_type",
          },
          {
            label: "Payment Terms",
            key: "payment_terms",
          },
          {
            label: "Currency",
            key: "document_currency_code",
          },
        ],
        itemSection: [
          {
            columnName: "Item Code",
            mappingField: "item_code",
          },
          {
            columnName: "Item / Service Description",
            mappingField: "description",
          },
          {
            columnName: "Quantity",
            mappingField: "quantity",
            isNumeric: true,
          },
          {
            columnName: "Unit Rate",
            mappingField: "unit_rate",
            isNumeric: true,
          },
          {
            columnName: "Discount",
            mappingField: "discount_amount",
            isNumeric: true,
          },
          {
            columnName: "Tax",
            mappingField: "tax",
            isNumeric: true,
          },
          {
            columnName: "Tax Code",
            mappingField: "tax_category_code",
          },
          {
            columnName: "Amount",
            mappingField: "total_amount",
            isNumeric: true,
          },
        ],
        actions: [
          {
            label: "Back",
            action: "back",
            target: "/self-billed-credit-notes",
          },
        ],
        sellerSection: {
          title: "Billed By",
          subtitle: "Vendor Info",
          fields: [
            {
              label: "Contact Person",
              key: "billed_by.name",
            },
            {
              label: "Company Name",
              key: "billed_by.name",
            },
            {
              label: "Address",
              key: "billed_by",
            },
            {
              label: "Country",
              key: "billed_by.country_code",
            },
            {
              label: "Phone",
              key: "billed_by.phone",
            },
            {
              label: "Email",
              key: "billed_by.email",
            },
            {
              label: "CR",
              key: "billed_by.registration_number",
            },
            {
              label: "TIN",
              key: "billed_by.tin",
            },
            {
              label: "VAT/GST No",
              key: "billed_by.sst_registration_number",
            },
          ],
        },
        buyerSection: {
          title: "Billed To",
          subtitle: "Customer Info",
          fields: [
            {
              label: "Contact Person",
              key: "billed_to.name",
            },
            {
              label: "Company Name",
              key: "billed_to.name",
            },
            {
              label: "Address",
              key: "billed_to",
            },
            {
              label: "Country",
              key: "billed_to.country_code",
            },
            {
              label: "Phone",
              key: "billed_to.phone",
            },
            {
              label: "Email",
              key: "billed_to.email",
            },
            {
              label: "CR",
              key: "billed_to.registration_number",
            },
            {
              label: "TIN",
              key: "billed_to.tin",
            },
            {
              label: "VAT/GST No",
              key: "billed_to.sst_registration_number",
            },
          ],
        },
        paymentSection: {
          title: "Payment Info",
          subtitle: "Bank Details",
          fields: [
            {
              label: "Bank Country",
              key: "payment.bank_country",
            },
            {
              label: "Account Number",
              key: "payment.ac_no",
            },
            {
              label: "IBAN",
              key: "payment.iban",
            },
            {
              label: "Bank Name",
              key: "payment.bank_name",
            },
            {
              label: "SWIFT Code",
              key: "payment.swift_code",
            },
            {
              label: "Beneficiary Name",
              key: "payment.beneficiary_name",
            },
          ],
        },
        summarySection: [
          {
            label: "Sub Total",
            value: "sub_total",
          },
          {
            label: "Total Discount Amount",
            value: "total_item_discount",
          },
          {
            label: "Total Taxable Amount",
            value: "total_excluding_tax",
          },
          {
            label: "Total Tax",
            value: "total_tax_amount",
          },
          {
            label: "Grand Total",
            value: "grand_total",
          },
        ],
        grandTotalInWords: {
          label: "",
        },
      },
    },
    "self-billed-refunds": {
      name: "Self Billed Refunds",
      list: {
        title: "Self Billed Refunds",
        filters: [
          {
            type: "search",
            options: [
              {
                key: "document_number",
                placeholder: "Refund Number",
              },
              {
                key: "invoice_number",
                placeholder: "Invoice Number",
              },
            ],
          },
        ],
        documentStatus: {
          key: "irbm_response.status",
        },
        api: {
          url: "/refunds?document_type=14",
          method: "GET",
        },
        columns: [
          {
            columnName: "Refund No",
            mappingField: "document_number",
          },
          {
            columnName: "Invoice No",
            mappingField: "invoice_number",
          },
          {
            columnName: "Customer Info",
            mappingField: "billed_by.name",
          },
          {
            columnName: "Refund Date",
            mappingField: "document_date",
          },
          {
            columnName: "Amount",
            mappingField: "grand_total",
          },
          {
            columnName: "Actions",
            mappingField: "actions",
          },
        ],
        actions: [
          {
            label: "Details",
            action: "details",
            target: "/self-billed-refunds/:id",
          },
          {
            label: "Download PDF",
            action: "download-pdf",
          },
          {
            label: "Download XML",
            action: "download-xml",
            key: "irbm_response.reported_invoice",
          },
        ],
        leadingActions: [
          {
            label: "+ Raise Self Billed Refund",
            action: "create",
            target: "/self-billed-refunds/create",
          },
        ],
      },
      create: {
        title: "Add Self Billed Refund",
        component: "MYSSBRefundFormComponent",
        actions: [
          {
            label: "Cancel",
            action: "cancel",
            target: "/self-billed-refunds",
            variant: "outlined",
          },
          {
            label: "Save",
            action: "save",
            target: "/self-billed-refunds",
            variant: "contained",
          },
        ],
      },
      details: {
        title: "Self Billed Refund Details",
        api: {
          url: "/self-billed-refunds/:id",
          method: "GET",
        },
        irbmSection: [
          {
            label: "IRBM Status",
            key: "irbm_response.status",
          },
          {
            label: "Document UUID",
            key: "irbm_response.document_id",
          },
          {
            label: "Validation Steps",
            key: "irbm_response.validation_results.validation_steps",
          },
        ],
        refundSection: [
          {
            label: "Refund No",
            key: "document_number",
          },
          {
            label: "Invoice No",
            key: "invoice_number",
          },
          {
            label: "Refund Date",
            key: "document_date",
          },
          {
            label: "Refund Type",
            key: "document_type",
          },
          {
            label: "Payment Terms",
            key: "payment_terms",
          },
          {
            label: "Currency",
            key: "document_currency_code",
          },
        ],
        itemSection: [
          {
            columnName: "Item Code",
            mappingField: "item_code",
          },
          {
            columnName: "Item / Service Description",
            mappingField: "description",
          },
          {
            columnName: "Quantity",
            mappingField: "quantity",
            isNumeric: true,
          },
          {
            columnName: "Unit Rate",
            mappingField: "unit_rate",
            isNumeric: true,
          },
          {
            columnName: "Discount",
            mappingField: "discount_amount",
            isNumeric: true,
          },
          {
            columnName: "Tax",
            mappingField: "tax",
            isNumeric: true,
          },
          {
            columnName: "Tax Code",
            mappingField: "tax_category_code",
          },
          {
            columnName: "Amount",
            mappingField: "total_amount",
            isNumeric: true,
          },
        ],
        actions: [
          {
            label: "Back",
            action: "back",
            target: "/self-billed-refunds",
          },
        ],
        sellerSection: {
          title: "Billed By",
          subtitle: "Vendor Info",
          fields: [
            {
              label: "Contact Person",
              key: "billed_by.name",
            },
            {
              label: "Company Name",
              key: "billed_by.name",
            },
            {
              label: "Address",
              key: "billed_by",
            },
            {
              label: "Country",
              key: "billed_by.country_code",
            },
            {
              label: "Phone",
              key: "billed_by.phone",
            },
            {
              label: "Email",
              key: "billed_by.email",
            },
            {
              label: "CR",
              key: "billed_by.registration_number",
            },
            {
              label: "TIN",
              key: "billed_by.tin",
            },
            {
              label: "VAT/GST No",
              key: "billed_by.sst_registration_number",
            },
          ],
        },
        buyerSection: {
          title: "Billed To",
          subtitle: "Customer Info",
          fields: [
            {
              label: "Contact Person",
              key: "billed_to.name",
            },
            {
              label: "Company Name",
              key: "billed_to.name",
            },
            {
              label: "Address",
              key: "billed_to",
            },
            {
              label: "Country",
              key: "billed_to.country_code",
            },
            {
              label: "Phone",
              key: "billed_to.phone",
            },
            {
              label: "Email",
              key: "billed_to.email",
            },
            {
              label: "CR",
              key: "billed_to.registration_number",
            },
            {
              label: "TIN",
              key: "billed_to.tin",
            },
            {
              label: "VAT/GST No",
              key: "billed_to.sst_registration_number",
            },
          ],
        },
        paymentSection: {
          title: "Payment Info",
          subtitle: "Bank Details",
          fields: [
            {
              label: "Bank Country",
              key: "payment.bank_country",
            },
            {
              label: "Account Number",
              key: "payment.ac_no",
            },
            {
              label: "IBAN",
              key: "payment.iban",
            },
            {
              label: "Bank Name",
              key: "payment.bank_name",
            },
            {
              label: "SWIFT Code",
              key: "payment.swift_code",
            },
            {
              label: "Beneficiary Name",
              key: "payment.beneficiary_name",
            },
          ],
        },
        summarySection: [
          {
            label: "Sub Total",
            value: "sub_total",
          },
          {
            label: "Total Discount Amount",
            value: "total_item_discount",
          },
          {
            label: "Total Taxable Amount",
            value: "total_excluding_tax",
          },
          {
            label: "Total Tax",
            value: "total_tax_amount",
          },
          {
            label: "Grand Total",
            value: "grand_total",
          },
        ],
        grandTotalInWords: {
          label: "",
        },
      },
    },
    "self-billed-debit-notes": {
      name: "Self Billed Debit Notes",
      list: {
        title: "Self Billed Debit Notes",
        filters: [
          {
            type: "search",
            options: [
              {
                key: "document_number",
                placeholder: "Debit Note Number",
              },
              {
                key: "invoice_number",
                placeholder: "Invoice Number",
              },
            ],
          },
        ],
        documentStatus: {
          key: "irbm_response.status",
        },
        api: {
          url: "/debit-notes?document_type=13",
          method: "GET",
        },
        columns: [
          {
            columnName: "Debit Note No",
            mappingField: "document_number",
          },
          {
            columnName: "Invoice No",
            mappingField: "invoice_number",
          },
          {
            columnName: "Customer Info",
            mappingField: "billed_by.name",
          },
          {
            columnName: "Debit Note Date",
            mappingField: "document_date",
          },
          {
            columnName: "Amount",
            mappingField: "grand_total",
          },
          {
            columnName: "Actions",
            mappingField: "actions",
          },
        ],
        actions: [
          {
            label: "Details",
            action: "details",
            target: "/self-billed-debit-notes/:id",
          },
          {
            label: "Download PDF",
            action: "download-pdf",
          },
          {
            label: "Download XML",
            action: "download-xml",
            key: "irbm_response.reported_invoice",
          },
        ],
        leadingActions: [
          {
            label: "+ Raise Self Billed Debit Note",
            action: "create",
            target: "/self-billed-debit-notes/create",
          },
        ],
      },
      create: {
        title: "Add Self Billed Debit Note",
        component: "MYSSBDebitNoteFormComponent",
        actions: [
          {
            label: "Cancel",
            action: "cancel",
            target: "/self-billed-debit-notes",
            variant: "outlined",
          },
          {
            label: "Save",
            action: "save",
            target: "/self-billed-debit-notes",
            variant: "contained",
          },
        ],
      },
      details: {
        title: "Self Billed Debit Note Details",
        api: {
          url: "/self-billed-debit-notes/:id",
          method: "GET",
        },
        irbmSection: [
          {
            label: "IRBM Status",
            key: "irbm_response.status",
          },
          {
            label: "Document UUID",
            key: "irbm_response.document_id",
          },
          {
            label: "Validation Steps",
            key: "irbm_response.validation_results.validation_steps",
          },
        ],
        debitNoteSection: [
          {
            label: "Debit Note No",
            key: "document_number",
          },
          {
            label: "Invoice No",
            key: "invoice_number",
          },
          {
            label: "Debit Note Date",
            key: "document_date",
          },
          {
            label: "Debit Note Type",
            key: "document_type",
          },
          {
            label: "Payment Terms",
            key: "payment_terms",
          },
          {
            label: "Currency",
            key: "document_currency_code",
          },
        ],
        itemSection: [
          {
            columnName: "Item Code",
            mappingField: "item_code",
          },
          {
            columnName: "Item / Service Description",
            mappingField: "description",
          },
          {
            columnName: "Quantity",
            mappingField: "quantity",
            isNumeric: true,
          },
          {
            columnName: "Unit Rate",
            mappingField: "unit_rate",
            isNumeric: true,
          },
          {
            columnName: "Discount",
            mappingField: "discount_amount",
            isNumeric: true,
          },
          {
            columnName: "Tax",
            mappingField: "tax",
            isNumeric: true,
          },
          {
            columnName: "Tax Code",
            mappingField: "tax_category_code",
          },
          {
            columnName: "Amount",
            mappingField: "total_amount",
            isNumeric: true,
          },
        ],
        actions: [
          {
            label: "Back",
            action: "back",
            target: "/self-billed-debit-notes",
          },
        ],
        sellerSection: {
          title: "Billed By",
          subtitle: "Vendor Info",
          fields: [
            {
              label: "Contact Person",
              key: "billed_by.name",
            },
            {
              label: "Company Name",
              key: "billed_by.name",
            },
            {
              label: "Address",
              key: "billed_by",
            },
            {
              label: "Country",
              key: "billed_by.country_code",
            },
            {
              label: "Phone",
              key: "billed_by.phone",
            },
            {
              label: "Email",
              key: "billed_by.email",
            },
            {
              label: "CR",
              key: "billed_by.registration_number",
            },
            {
              label: "TIN",
              key: "billed_by.tin",
            },
            {
              label: "VAT/GST No",
              key: "billed_by.sst_registration_number",
            },
          ],
        },
        buyerSection: {
          title: "Billed To",
          subtitle: "Customer Info",
          fields: [
            {
              label: "Contact Person",
              key: "billed_to.name",
            },
            {
              label: "Company Name",
              key: "billed_to.name",
            },
            {
              label: "Address",
              key: "billed_to",
            },
            {
              label: "Country",
              key: "billed_to.country_code",
            },
            {
              label: "Phone",
              key: "billed_to.phone",
            },
            {
              label: "Email",
              key: "billed_to.email",
            },
            {
              label: "CR",
              key: "billed_to.registration_number",
            },
            {
              label: "TIN",
              key: "billed_to.tin",
            },
            {
              label: "VAT/GST No",
              key: "billed_to.sst_registration_number",
            },
          ],
        },
        paymentSection: {
          title: "Payment Info",
          subtitle: "Bank Details",
          fields: [
            {
              label: "Bank Country",
              key: "payment.bank_country",
            },
            {
              label: "Account Number",
              key: "payment.ac_no",
            },
            {
              label: "IBAN",
              key: "payment.iban",
            },
            {
              label: "Bank Name",
              key: "payment.bank_name",
            },
            {
              label: "SWIFT Code",
              key: "payment.swift_code",
            },
            {
              label: "Beneficiary Name",
              key: "payment.beneficiary_name",
            },
          ],
        },
        summarySection: [
          {
            label: "Sub Total",
            value: "sub_total",
          },
          {
            label: "Total Discount Amount",
            value: "total_item_discount",
          },
          {
            label: "Total Taxable Amount",
            value: "total_excluding_tax",
          },
          {
            label: "Total Tax",
            value: "total_tax_amount",
          },
          {
            label: "Grand Total",
            value: "grand_total",
          },
        ],
        grandTotalInWords: {
          label: "",
        },
      },
    },
  },
};
