import { takeLatest, call, put } from "redux-saga/effects";
import { apiClient } from "../../utils/axios";
import {
  fetchSupplierById,
  fetchSupplierByIdFailure,
  fetchSupplierByIdSuccess,
  fetchSuppliersFailure,
  fetchSuppliersRequest,
  fetchSuppliersSuccess,
  createSupplierRequest,
  createSupplierSuccess,
  createSupplierFailure,
} from "./suppliersSlice";

function* fetchSuppliersSaga(action) {
  try {
    const { url, page, limit, search_key, search_by } = action.payload;
    const params = {
      page,
      limit,
      ...(search_key && search_by && { search_key, search_by }),
    };
    const response = yield call(apiClient.get, url, { params });
    yield put(fetchSuppliersSuccess(response.data));
  } catch (error) {
    yield put(fetchSuppliersFailure(error.message));
  }
}

function* fetchSupplierByIdSaga(action) {
  try {
    const response = yield call(apiClient.get, `/suppliers/${action.payload}`);
    yield put(fetchSupplierByIdSuccess(response.data));
  } catch (error) {
    yield put(fetchSupplierByIdFailure(error.message));
  }
}

function* createSupplierSaga(action) {
  try {
    const response = yield call(apiClient.post, "/suppliers", action.payload);
    yield put(createSupplierSuccess(response.data));
  } catch (error) {
    yield put(
      createSupplierFailure(JSON.stringify(error.response.data.errors)),
    );
  }
}

function* suppliersSaga() {
  yield takeLatest(fetchSuppliersRequest.type, fetchSuppliersSaga);
  yield takeLatest(fetchSupplierById.type, fetchSupplierByIdSaga);
  yield takeLatest(createSupplierRequest.type, createSupplierSaga);
}

export default suppliersSaga;
