import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  businessProfiles: [],
  selectedBusinessProfile: null,
  currentPage: 1,
  totalPages: 1,
  totalDocuments: 0,
  loading: false,
  isBusinessProfileCreated: false,
  error: null,
};

const businessProfilesSlice = createSlice({
  name: "businessProfiles",
  initialState,
  reducers: {
    fetchBusinessProfilesRequest(state) {
      state.loading = true;
      state.error = null;
    },
    fetchBusinessProfilesSuccess(state, action) {
      state.loading = false;
      state.businessProfiles = action.payload.business_profiles;
      state.currentPage = action.payload.currentPage;
      state.totalPages = action.payload.totalPages;
      state.totalDocuments = action.payload.totalDocuments;
    },
    fetchBusinessProfilesFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    fetchBusinessProfileById(state, action) {
      state.loading = true;
      state.error = null;
    },
    fetchBusinessProfileByIdSuccess(state, action) {
      state.loading = false;
      state.selectedBusinessProfile = action.payload;
    },
    fetchBusinessProfileByIdFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    createBusinessProfileRequest(state, action) {
      state.loading = true;
      state.error = null;
      state.isBusinessProfileCreated = false;
    },
    createBusinessProfileSuccess(state, action) {
      state.loading = false;
      state.error = null;
      state.isBusinessProfileCreated = true;
    },
    createBusinessProfileFailure(state, action) {
      state.loading = false;
      state.isBusinessProfileCreated = false;
      state.error = action.payload;
    },
    markBusinessProfileCreated(state, action) {
      state.isBusinessProfileCreated = action.payload;
    },
  },
});

export const {
  fetchBusinessProfilesRequest,
  fetchBusinessProfilesSuccess,
  fetchBusinessProfilesFailure,
  fetchBusinessProfileById,
  fetchBusinessProfileByIdSuccess,
  fetchBusinessProfileByIdFailure,
  createBusinessProfileRequest,
  createBusinessProfileSuccess,
  createBusinessProfileFailure,
  markBusinessProfileCreated,
} = businessProfilesSlice.actions;

export default businessProfilesSlice.reducer;
