import React from "react";
import { MenuItem } from "@mui/material";
import { useNavigate } from "react-router-dom";

export const GoToDetailsAction = ({ action, supplier }) => {
  const navigate = useNavigate();

  const onClickingDetails = () => {
    navigate(action.target.replace(":id", supplier.profile_id));
  };

  return (
    <MenuItem key={action.action} onClick={onClickingDetails}>
      {action.label}
    </MenuItem>
  );
};
