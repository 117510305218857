export const getStateName = (code, states) => {
  const state = states.find((state) => state.Code === code);
  return state ? state.State : code;
};

export const getCountryName = (code, countryCodes) => {
  const country = countryCodes.find((country) => country.Code === code);
  return country ? country.Country : code;
};

export const getMISCCode = (code, msicCodes) => {
  const msic = msicCodes.find((msic) => msic.Code === code);
  return msic ? msic.Description : code;
};

export const getTaxCode = (code, taxCodes) => {
  const tax = taxCodes.find((tax) => tax.Code === code);
  return tax ? tax.Description : code;
};

export const convertConfigToFormData = (data) => {
  return data.reduce((acc, item) => {
    acc[item.key] = item.value || "";
    return acc;
  }, {});
};

export const getRequiredFields = (formDataArrays) => {
  return formDataArrays.flatMap((formDataArray) =>
    formDataArray.filter((field) => field.required).map((field) => field.key),
  );
};
