import { Grid, Box, TextField, Typography, InputLabel } from "@mui/material";

export default function SummarySection({ formData, effectiveInvoiceTotal }) {
  return (
    <Box sx={{ mt: 2, display: "flex" }}>
      <Grid item xs={4}>
        <InputLabel sx={{ color: "black", fontSize: "0.875rem" }}>
          Effective Invoice Total ({formData.document_currency_code})
        </InputLabel>
        <TextField
          variant="outlined"
          value={effectiveInvoiceTotal}
          InputProps={{ readOnly: true }}
          fullWidth
          size="small"
          sx={{ backgroundColor: "#f5f5f5" }}
        />
      </Grid>

      <Grid item xs={4}>
        <Typography sx={{ textAlign: "center", mt: 3, fontWeight: "bold" }}>
          -
        </Typography>
      </Grid>

      <Grid item xs={4}>
        <InputLabel sx={{ color: "black", fontSize: "0.875rem" }}>
          Credit Note Net Total ({formData.document_currency_code})
        </InputLabel>
        <TextField
          variant="outlined"
          size="small"
          value={formData.grand_total}
          InputProps={{ readOnly: true }}
          fullWidth
          sx={{ backgroundColor: "#f5f5f5" }}
        />
      </Grid>

      <Grid item xs={4}>
        <Typography sx={{ textAlign: "center", mt: 3, fontWeight: "bold" }}>
          =
        </Typography>
      </Grid>

      <Grid item xs={4}>
        <InputLabel sx={{ color: "black", fontSize: "0.875rem" }}>
          Effective Sale Value ({formData.document_currency_code})
        </InputLabel>
        <TextField
          variant="outlined"
          size="small"
          value={effectiveInvoiceTotal - formData.grand_total}
          InputProps={{ readOnly: true }}
          fullWidth
          sx={{ backgroundColor: "#f5f5f5" }}
        />
      </Grid>
    </Box>
  );
}
