import { takeLatest, call, put } from "redux-saga/effects";
import {
  createDebitNoteFailure,
  createDebitNoteRequest,
  createDebitNoteSuccess,
  fetchDebitNoteById,
  fetchDebitNoteByIdFailure,
  fetchDebitNoteByIdSuccess,
  fetchDebitNotesFailure,
  fetchDebitNotesRequest,
  fetchDebitNotesSuccess,
} from "./debitNotesSlice";
import { apiClient } from "../../utils/axios";

function* fetchDebitNotesSaga(action) {
  try {
    const { url, page, limit, search_key, search_by } = action.payload;
    const params = {
      page,
      limit,
      ...(search_key && search_by && { search_key, search_by }),
    };
    const response = yield call(apiClient.get, url, { params });
    yield put(fetchDebitNotesSuccess(response.data));
  } catch (error) {
    yield put(fetchDebitNotesFailure(error.message));
  }
}

function* fetchDebitNoteByIdSaga(action) {
  try {
    const response = yield call(
      apiClient.get,
      `/debit-notes/${action.payload}`,
    );
    yield put(fetchDebitNoteByIdSuccess(response.data));
  } catch (error) {
    yield put(fetchDebitNoteByIdFailure(error.message));
  }
}

function* createDebitNoteSaga(action) {
  try {
    const response = yield call(apiClient.post, "/debit-notes", action.payload);
    yield put(createDebitNoteSuccess(response.data));
  } catch (error) {
    yield put(createDebitNoteFailure(error.message));
  }
}

function* debitNotesSaga() {
  yield takeLatest(fetchDebitNotesRequest.type, fetchDebitNotesSaga);
  yield takeLatest(fetchDebitNoteById.type, fetchDebitNoteByIdSaga);
  yield takeLatest(createDebitNoteRequest.type, createDebitNoteSaga);
}

export default debitNotesSaga;
