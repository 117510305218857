import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  taxTypes: [],
  loading: false,
  error: null,
};

const taxTypesSlice = createSlice({
  name: "taxTypes",
  initialState,
  reducers: {
    fetchTaxTypesRequest(state) {
      state.loading = true;
      state.error = null;
    },
    fetchTaxTypesSuccess(state, action) {
      state.loading = false;
      state.taxTypes = action.payload;
    },
    fetchTaxTypesFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  fetchTaxTypesRequest,
  fetchTaxTypesSuccess,
  fetchTaxTypesFailure,
} = taxTypesSlice.actions;

export default taxTypesSlice.reducer;
