import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  InputLabel,
  Typography,
  FormControl,
  TextField,
} from "@mui/material";
import TopBar from "../../../../components/TopBar";
import { useDispatch, useSelector } from "react-redux";
import ProfileCard from "../../../common/ProfileCard";
import { fetchTaxTypesRequest } from "../../../../api/constants/taxTypesSlice";
import { fetchUomCodesRequest } from "../../../../api/constants/uomSlice";
import { fetchPaymentTermsRequest } from "../../../../api/constants/paymentMeansSlice";
import { fetchItemCodesRequest } from "../../../../api/constants/itemCodesSlice";
import MYSItemDetails from "./MYSItemDetails";
import { useLocation } from "react-router-dom";
import { getCountryName, getStateName } from "../../../../utils/helper";
import useDocumentTitle from "../../../../hooks/useDocumentTitle";
import CustomStringInput from "../../../../components/CustomStringInput";
import CustomSelectInput from "../../../../components/CustomSelectInput";
import CustomDateInput from "../../../../components/CustomDateInput";
import SummarySection from "../../../common/SummarySection";
import CreateRefundActions from "../CreateRefundActions";
import pricingEngineForDocuments from "../../../../utils/pricingEngineForDocuments";

const sellerSection = [
  {
    label: "Contact Person",
    key: "name",
  },
  {
    label: "Company Name",
    key: "name",
  },
  {
    label: "Address",
    key: "",
  },
  {
    label: "Country",
    key: "country_code",
  },
  {
    label: "Phone",
    key: "phone",
  },
  {
    label: "Email",
    key: "email",
  },
  {
    label: "CR",
    key: "registration_number",
  },
  {
    label: "TIN",
    key: "tin",
  },
  {
    label: "VAT/GST No",
    key: "sst_registration_number",
  },
];

const buyerSection = [
  {
    label: "Contact Person",
    key: "name",
  },
  {
    label: "Company Name",
    key: "name",
  },
  {
    label: "Address",
    key: "",
  },
  {
    label: "Country",
    key: "country_code",
  },
  {
    label: "Phone",
    key: "phone",
  },
  {
    label: "Email",
    key: "email",
  },
  {
    label: "CR",
    key: "registration_number",
  },
  {
    label: "TIN",
    key: "tin",
  },
  {
    label: "VAT/GST No",
    key: "sst_registration_number",
  },
];

const requiredFields = [
  "document_number",
  "document_date",
  "invoice_number",
  "document_time",
  "transaction_type",
  "document_type",
  "document_currency_code",
  "billed_by",
  "billed_to",
  "item_details",
  "sub_total",
  "grand_total",
  "total_excluding_tax",
  "total_including_tax",
  "total_payable_amount",
];

export default function MYSRefundForm({ config }) {
  useDocumentTitle("Refund Form");
  const location = useLocation();

  const invoice = location.state.invoiceData;
  const [items, setItems] = useState(invoice?.item_details || []);
  const [errors, setErrors] = useState({});

  const [formData, setFormData] = useState({
    document_number: "",
    document_date: "",
    document_time: new Date().toISOString().slice(11, 19) + "Z",
    invoice_number: invoice?.document_number || "",
    transaction_type: invoice?.transaction_type || "B2B",
    document_type: "04",
    document_currency_code: invoice?.document_currency_code || "MYR",
    billed_by: invoice?.billed_by || "",
    billed_to: invoice?.billed_to || "",
    item_details: items,
    sub_total: invoice?.sub_total || 0,
    grand_total: invoice?.grand_total || 0,
    total_excluding_tax: invoice?.total_excluding_tax || 0,
    total_including_tax: invoice?.total_including_tax || 0,
    total_payable_amount: invoice?.total_payable_amount || 0,
    payment_mode: invoice?.payment_mode || "",
    payment_terms: invoice?.payment_terms || "",
    header_discount: invoice?.header_discount || 0,
    total_tax_amount: invoice?.total_tax_amount || 0,
    total_item_discount: invoice?.total_item_discount || 0,
  });
  const countryCodes = useSelector((state) => state.countryCodes.countryCodes);
  const states = useSelector((state) => state.states.states);
  const taxTypes = useSelector((state) => state.taxTypes.taxTypes);
  const paymentMeans = useSelector((state) => state.paymentMeans.paymentMeans);
  const itemCodes = useSelector((state) => state.itemCodes.itemCodes);
  const uomCodes = useSelector((state) => state.uomCodes.uomCodes);
  const effectiveInvoiceTotal = invoice?.grand_total || 0;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchTaxTypesRequest({ search_key: "tax_type" }));
    dispatch(fetchPaymentTermsRequest({ search_key: "payment_means" }));
    dispatch(fetchItemCodesRequest({ search_key: "item_code" }));
    dispatch(fetchUomCodesRequest({ search_key: "uom" }));
  }, [dispatch]);

  useEffect(() => {
    const {
      subtotal,
      totalTaxAmount,
      totalItemDiscount,
      totalExcludingTax,
      totalIncludingTax,
      totalPayableAmount,
      totalHeaderDiscount,
      grandTotal,
    } = pricingEngineForDocuments(items, formData.header_discount);

    setFormData((prevData) => ({
      ...prevData,
      item_details: items,
      sub_total: subtotal,
      total_tax_amount: totalTaxAmount,
      total_item_discount: totalItemDiscount,
      total_excluding_tax: totalExcludingTax,
      total_including_tax: totalIncludingTax,
      total_payable_amount: totalPayableAmount,
      header_discount: totalHeaderDiscount,
      grand_total: grandTotal,
    }));
  }, [items, formData.header_discount]);

  const { title, actions } = config;

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevData) => ({ ...prevData, [name]: value }));

    setErrors((prevErrors) => {
      const newErrors = { ...prevErrors };
      if (newErrors[name]) {
        delete newErrors[name];
      }
      return newErrors;
    });
  };

  return (
    <Box sx={{ p: 4 }} className="InvoiceDetails">
      <TopBar title={title}>
        <CreateRefundActions
          formData={formData}
          requiredFields={requiredFields}
          setErrors={setErrors}
          actions={actions}
        />
      </TopBar>
      <Grid container spacing={2}>
        <CustomStringInput
          field={{
            label: "Document Number",
            key: "document_number",
            required: true,
            size: 6,
          }}
          formData={formData}
          handleChange={handleChange}
          errors={errors}
        />
        <CustomStringInput
          field={{
            label: "Invoice Number",
            key: "invoice_number",
            required: true,
            size: 6,
          }}
          formData={formData}
          handleChange={handleChange}
          errors={errors}
          readOnly={true}
        />
        <CustomDateInput
          field={{
            label: "Refund Date",
            key: "document_date",
            required: true,
            size: 6,
          }}
          formData={formData}
          handleChange={handleChange}
          errors={errors}
        />
        <Grid item xs={12} sm={6} key="invoice_date">
          <InputLabel sx={{ color: "black", mb: -1.5, fontSize: "0.875rem" }}>
            Invoice Date: <span style={{ color: "red" }}>*</span>
          </InputLabel>
          <TextField
            placeholder="Invoice Date"
            value={invoice?.document_date || ""}
            fullWidth
            margin="normal"
            size="small"
            inputProps={{ maxLength: 300 }}
            InputProps={{
              readOnly: true,
              sx: { fontSize: "0.875rem" },
            }}
          />
        </Grid>
        <CustomSelectInput
          field={{
            label: "Payment Mode",
            key: "payment_mode",
            required: false,
            size: 6,
          }}
          formData={formData}
          handleChange={handleChange}
          errors={errors}
          options={paymentMeans}
          labelKey="Payment Method"
          valueKey="Code"
        />
        <CustomStringInput
          field={{
            label: "Payment Terms",
            key: "payment_terms",
            required: false,
            size: 6,
          }}
          formData={formData}
          handleChange={handleChange}
          errors={errors}
        />
        <Grid item xs={12} sm={6}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <InputLabel sx={{ color: "black", mb: -1.5, fontSize: "0.875rem" }}>
              Billed By: <span style={{ color: "red" }}>*</span>
            </InputLabel>
          </Box>

          <FormControl fullWidth margin="normal">
            <TextField
              value={`${formData.billed_by?.name} | ${formData.billed_by?.email} | ${formData.billed_by?.registration_number}`}
              variant="outlined"
              size="small"
              sx={{ fontSize: "0.875rem" }}
              aria-readonly={true}
            />
          </FormControl>

          {formData.billed_by && (
            <Box sx={{ mt: 2, fontSize: "0.875rem" }}>
              <ProfileCard
                title="Billed By"
                subtitle="Vendor Info"
                fields={sellerSection}
                document={{
                  ...formData.billed_by,
                  state: getStateName(formData.billed_by.state, states),
                  country_code: getCountryName(
                    formData.billed_by.country_code,
                    countryCodes,
                  ),
                }}
                applyRightMargin={true}
                fixedHeight={true}
              />
            </Box>
          )}
        </Grid>

        <Grid item xs={12} sm={6}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <InputLabel sx={{ color: "black", mb: -1.5, fontSize: "0.875rem" }}>
              Billed To: <span style={{ color: "red" }}>*</span>
            </InputLabel>
          </Box>

          <FormControl fullWidth margin="normal">
            <TextField
              value={`${formData.billed_to?.name} | ${formData.billed_to?.email} | ${formData.billed_to?.registration_number}`}
              variant="outlined"
              size="small"
              sx={{ fontSize: "0.875rem" }}
              aria-readonly={true}
            />
          </FormControl>

          {formData.billed_to && (
            <Box sx={{ mt: 2, fontSize: "0.875rem" }}>
              <ProfileCard
                title="Billed To"
                subtitle="Customer Info"
                fields={buyerSection}
                document={{
                  ...formData.billed_to,
                  state: getStateName(formData.billed_to.state, states),
                  country_code: getCountryName(
                    formData.billed_to.country_code,
                    countryCodes,
                  ),
                }}
                applyRightMargin={true}
                fixedHeight={true}
              />
            </Box>
          )}
        </Grid>

        <Grid item xs={12}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography variant="h6">Item Details</Typography>
            <FormControl margin="normal">
              <TextField
                value={formData.document_currency_code || ""}
                label="Currency"
                variant="outlined"
                size="small"
                sx={{ fontSize: "0.875rem" }}
                aria-readonly={true}
                InputLabelProps={{
                  style: { color: "black" },
                }}
              />
            </FormControl>
          </Box>
          <MYSItemDetails
            items={items}
            setItems={setItems}
            taxTypes={taxTypes}
            itemCodes={itemCodes}
            uomCodes={uomCodes}
          />
        </Grid>
        <Grid item xs={12}>
          <Box className="summary-details-container">
            <Box className="summary-item">
              <Typography className="summary-label">Subtotal:</Typography>
              <span className="summary-value">{formData.sub_total}</span>
            </Box>
            <Box className="summary-item">
              <Typography className="summary-label">
                Total Excluding Tax:
              </Typography>
              <span className="summary-value">
                {formData.total_excluding_tax}
              </span>
            </Box>
            <Box className="summary-item">
              <Typography className="summary-label">
                Total Including Tax:
              </Typography>
              <span className="summary-value">
                {formData.total_including_tax}
              </span>
            </Box>
            <Box className="summary-item">
              <Typography className="summary-label">
                Total Payable Amount:
              </Typography>
              <span className="summary-value">
                {formData.total_payable_amount}
              </span>
            </Box>
            <Box className="summary-item">
              <Typography className="summary-label">
                Header Discount:
              </Typography>
              <CustomStringInput
                field={{
                  key: "header_discount",
                  required: false,
                  size: 2,
                }}
                formData={formData}
                handleChange={handleChange}
                errors={errors}
                readOnly={true}
              />
            </Box>
            <Box className="summary-item">
              <Typography className="summary-label">
                Total Tax Amount:
              </Typography>
              <span className="summary-value">{formData.total_tax_amount}</span>
            </Box>
            <Box className="summary-item">
              <Typography className="summary-label">
                Total Item Discount:
              </Typography>
              <span className="summary-value">
                {formData.total_item_discount}
              </span>
            </Box>
            <hr className="summary-divider" />
            <Box className="summary-item">
              <Typography className="summary-label" sx={{ fontWeight: "bold" }}>
                Grand Total:
              </Typography>
              <span className="summary-value">{formData.grand_total}</span>
            </Box>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{ display: "flex", flexDirection: "row-reverse" }}
        >
          <SummarySection
            formData={formData}
            effectiveInvoiceTotal={effectiveInvoiceTotal}
          />
        </Grid>
      </Grid>
    </Box>
  );
}
