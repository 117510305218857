import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CircularProgress } from "@mui/material";
import CustomTable from "../../components/CustomTable";
import DebitNoteActions from "./DebitNoteActions";
import DebitNoteFilters from "./DebitNoteFilters";
import {
  fetchDebitNotesRequest,
  markDebitNoteCreated,
} from "../../api/debitNotes/debitNotesSlice";
import TopBar from "../../components/TopBar";
import useDocumentTitle from "../../hooks/useDocumentTitle";
import LeadingActions from "../creditNotes/LeadingActions";

function DebitNoteList({ config }) {
  useDocumentTitle("Debit Note List");
  const dispatch = useDispatch();
  const { debitNotes, currentPage, totalDocuments, loading, error } =
    useSelector((state) => state.debitNotes);
  const {
    filters,
    title,
    api,
    columns,
    actions,
    leadingActions,
    documentStatus,
  } = config;
  const [selectedFilter, setSelectedFilter] = useState(
    filters.length > 0 ? filters[0].options[0].key : "",
  );
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    dispatch(markDebitNoteCreated(false));
    dispatch(fetchDebitNotesRequest({ url: api.url, page: 1, limit: 10 }));
  }, [dispatch, api.url]);

  if (loading)
    return (
      <CircularProgress sx={{ display: "block", margin: "auto", mt: 4 }} />
    );
  if (error) return <div>Error: {error}</div>;

  const handleChangePage = (event, newPage) => {
    dispatch(
      fetchDebitNotesRequest({ url: api.url, page: newPage + 1, limit: 10 }),
    );
  };

  const handleChangeRowsPerPage = (event) => {
    const newLimit = parseInt(event.target.value, 10);
    dispatch(
      fetchDebitNotesRequest({ url: api.url, page: 1, limit: newLimit }),
    );
  };

  const handleFilterChange = (event) => {
    setSelectedFilter(event.target.value);
    dispatch(
      fetchDebitNotesRequest({
        url: api.url,
        page: 1,
        limit: 10,
        search_by: event.target.value,
        search_key: searchTerm,
      }),
    );
  };

  const handleSearchChange = (event) => setSearchTerm(event.target.value);

  const handleSearchKeyPress = (event) => {
    if (event.key === "Enter") {
      dispatch(
        fetchDebitNotesRequest({
          url: api.url,
          page: 1,
          limit: 10,
          search_by: selectedFilter,
          search_key: searchTerm,
        }),
      );
    }
  };

  return (
    <>
      <TopBar title={title}>
        <LeadingActions actions={leadingActions} />
      </TopBar>
      <DebitNoteFilters
        filters={filters}
        selectedFilter={selectedFilter}
        onFilterChange={handleFilterChange}
        searchTerm={searchTerm}
        onSearchChange={handleSearchChange}
        onSearchKeyPress={handleSearchKeyPress}
      />
      <CustomTable
        columns={columns}
        filteredData={debitNotes}
        documentStatus={documentStatus}
        currentPage={currentPage}
        totalCount={totalDocuments}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        ActionsComponent={DebitNoteActions}
        childComponentProps={{ actions }}
      />
    </>
  );
}

export default DebitNoteList;
