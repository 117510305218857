import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchBusinessProfileById } from "../../api/businessProfiles/businessProfilesSlice";
import { Box, Paper, Typography, useTheme, Divider } from "@mui/material";
import "../../styles/DocumentDetails.css";
import TopBar from "../../components/TopBar";
import DetailsPageActions from "./details/DetailsPageAction";
import InfoCard from "../common/InfoCard";
import { getCountryName, getMISCCode, getStateName } from "../../utils/helper";
import useDocumentTitle from "../../hooks/useDocumentTitle";

export default function BusinessProfileDetails({ config }) {
  useDocumentTitle("Business Profile Details");
  const { profile_id } = useParams();
  const dispatch = useDispatch();
  const businessProfile = useSelector(
    (state) => state.businessProfiles.selectedBusinessProfile,
  );

  const loading = useSelector((state) => state.businessProfiles.loading);
  const theme = useTheme();

  const states = useSelector((state) => state.states.states);
  const countryCodes = useSelector((state) => state.countryCodes.countryCodes);
  const msicCodes = useSelector((state) => state.msicCodes.msicCodes);

  useEffect(() => {
    if (profile_id) {
      dispatch(fetchBusinessProfileById(profile_id));
    }
  }, [dispatch, profile_id]);

  if (loading) {
    return (
      <Box className="centered-box">
        <Typography variant="h6" component="div">
          Loading...
        </Typography>
      </Box>
    );
  }

  const {
    businessProfileSection,
    actions,
    title,
    addressSection,
    identifiersSection,
  } = config;

  return (
    <Box>
      {businessProfile && (
        <TopBar title={title} subTitle={businessProfile?.profile_id}>
          <DetailsPageActions actions={actions} />
        </TopBar>
      )}

      <Paper>
        {businessProfile && (
          <InfoCard
            document={{
              ...businessProfile,
              state: getStateName(businessProfile.state, states),
              country_code: getCountryName(
                businessProfile.country_code,
                countryCodes,
              ),
              msic_code: getMISCCode(businessProfile.msic_code, msicCodes),
            }}
            data={businessProfileSection}
          />
        )}

        <Box sx={{ display: "flex", alignItems: "center", my: 2 }}>
          <Divider sx={{ flex: 1 }} />
          <Typography
            variant="subtitle2"
            sx={{
              mx: 2,
              color: theme.palette.text.disabled,
              textAlign: "center",
            }}
          >
            ADDRESS
          </Typography>
          <Divider sx={{ flex: 1 }} />
        </Box>

        {businessProfile && (
          <InfoCard
            document={{
              ...businessProfile,
              state: getStateName(businessProfile.state, states),
              country_code: getCountryName(
                businessProfile.country_code,
                countryCodes,
              ),
              msic_code: getMISCCode(businessProfile.msic_code, msicCodes),
            }}
            data={addressSection}
          />
        )}

        <Box sx={{ display: "flex", alignItems: "center", my: 2 }}>
          <Divider sx={{ flex: 1 }} />
          <Typography
            variant="subtitle2"
            sx={{
              mx: 2,
              color: theme.palette.text.disabled,
              textAlign: "center",
            }}
          >
            Business Identifiers
          </Typography>
          <Divider sx={{ flex: 1 }} />
        </Box>

        {businessProfile && (
          <InfoCard
            document={{
              ...businessProfile,
              state: getStateName(businessProfile.state, states),
              country_code: getCountryName(
                businessProfile.country_code,
                countryCodes,
              ),
              msic_code: getMISCCode(businessProfile.msic_code, msicCodes),
            }}
            data={identifiersSection}
          />
        )}
      </Paper>
    </Box>
  );
}
