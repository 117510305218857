import React, { useEffect, useState } from "react";
import { Button, Grid, Paper } from "@mui/material";
import CustomStringInput from "../../../../components/CustomStringInput";
import CustomSelectInput from "../../../../components/CustomSelectInput";
import { formatDecimal } from "../../../../utils/formatDecimal";
import { DeleteButton } from "../../../../components/DeleteButton";

const requiredFields = [
  "description",
  "item_code",
  "unit_of_measure",
  "tax_category_code",
];

export default function MYSItemDetails({
  items,
  setItems,
  itemCodes,
  taxTypes,
  uomCodes,
}) {
  const [inputValues, setInputValues] = useState(items);
  const [errors, setErrors] = useState([]);

  const validateItem = (item) => {
    const newErrors = {};
    requiredFields.forEach((field) => {
      if (!item[field]) {
        newErrors[field] = `${field.replace(/_/g, " ")} is required`;
      }
    });
    return newErrors;
  };

  const handleItemChange = (index, field, value) => {
    const updatedItems = [...inputValues];

    if (field === "unit_rate") {
      value = formatDecimal(value, 4);
    } else if (
      [
        "discount_amount",
        "tax_rate",
        "quantity",
        "tax_amount",
        "total_amount",
      ].includes(field)
    ) {
      value = formatDecimal(value, 2);
    }

    updatedItems[index][field] = value;

    const updatedErrors = [...errors];
    if (requiredFields.includes(field)) {
      if (value.trim()) {
        delete updatedErrors[index]?.[field];
      } else {
        updatedErrors[index] = {
          ...updatedErrors[index],
          [field]: `${field.replace(/_/g, " ")} is required`,
        };
      }
    }

    if (
      [
        "discount_amount",
        "discount_type",
        "tax_rate",
        "quantity",
        "unit_rate",
      ].includes(field)
    ) {
      updatedItems[index] = recalculateAmounts(updatedItems[index]);
    }

    setInputValues(updatedItems);
    setErrors(updatedErrors);
  };

  const recalculateAmounts = (item) => {
    const baseAmount = item.quantity * item.unit_rate;
    const discountAmount =
      item.discount_type === "percentage"
        ? (baseAmount * item.discount_amount) / 100
        : item.discount_amount;
    const netAmount = baseAmount - discountAmount;
    const taxAmount = (netAmount * (item.tax_rate || 0)) / 100;
    const totalAmount = netAmount + taxAmount;

    return {
      ...item,
      discount_amount: Number(item.discount_amount || 0),
      unit_rate: Number(item.unit_rate || 0),
      quantity: Number(item.quantity || 0),
      tax_rate: Number(item.tax_rate || 0),
      base_amount: Number(baseAmount.toFixed(2)),
      net_amount: Number(netAmount.toFixed(2)),
      tax_amount: Number(taxAmount.toFixed(2)),
      total_amount: Number(totalAmount.toFixed(2)),
    };
  };

  const handleAddItem = () => {
    const newItem = {
      description: "",
      item_code: "",
      unit_of_measure: "",
      quantity: 1,
      unit_rate: 1,
      base_amount: 0,
      net_amount: 0,
      total_amount: 0,
      tax_category_code: "",
      tax_rate: 0,
      tax_amount: 0,
      discount_amount: 0,
      discount_type: "number",
    };
    setInputValues([...inputValues, newItem]);
  };

  const handleRemoveItem = (index) => {
    if (inputValues.length > 1) {
      const updatedItems = [...inputValues];
      updatedItems.splice(index, 1);
      setInputValues(updatedItems);
    }
  };

  const handleBlur = (index) => {
    const updatedItems = [...inputValues];
    const item = updatedItems[index];

    if (item.discount_type === "percentage") {
      const baseAmount = item.quantity * item.unit_rate;
      item.discount_amount =
        (baseAmount * parseFloat(item.discount_amount)) / 100;
    } else {
      item.discount_amount = parseFloat(item.discount_amount);
    }

    // Change type back to number
    item.discount_type = "number";

    updatedItems[index] = recalculateAmounts(item);
    setInputValues(updatedItems);

    const itemErrors = validateItem(item);
    setErrors((prevErrors) => {
      const newErrors = [...prevErrors];
      newErrors[index] = itemErrors;
      return newErrors;
    });
  };

  useEffect(() => {
    setItems(inputValues);
  }, [inputValues, setItems]);

  return (
    <>
      {inputValues.map((item, index) => (
        <Paper
          key={index}
          elevation={3}
          sx={{
            padding: "16px",
            marginBottom: "12px",
            backgroundColor: "#f8f8f8",
            borderRadius: "8px",
            position: "relative",
          }}
        >
          <DeleteButton
            onClick={() => handleRemoveItem(index)}
            disabled={inputValues.length === 1}
          >
            X
          </DeleteButton>

          <Grid container spacing={2} alignItems="center">
            <CustomStringInput
              field={{
                label: "Item Description",
                key: "description",
                required: true,
                size: 3,
              }}
              formData={item}
              handleChange={(e) =>
                handleItemChange(index, "description", e.target.value)
              }
              errors={errors[index] || {}}
              onBlur={() => handleBlur(index)}
            />

            <CustomStringInput
              field={{ label: "Qty", key: "quantity", required: true, size: 1 }}
              formData={item}
              handleChange={(e) =>
                handleItemChange(index, "quantity", e.target.value)
              }
              errors={errors[index] || {}}
              onBlur={() => handleBlur(index)}
              type="number"
            />

            <CustomStringInput
              field={{
                label: "Rate",
                key: "unit_rate",
                required: true,
                size: 1,
              }}
              formData={item}
              handleChange={(e) =>
                handleItemChange(index, "unit_rate", e.target.value)
              }
              errors={errors[index] || {}}
              onBlur={() => handleBlur(index)}
              type="number"
            />

            <CustomSelectInput
              field={{
                label: "Discount Type",
                key: "discount_type",
                required: true,
                size: 2,
              }}
              formData={item}
              handleChange={(e) =>
                handleItemChange(index, "discount_type", e.target.value)
              }
              options={[
                { value: "number", label: "Number" },
                { value: "percentage", label: "Percentage" },
              ]}
              labelKey="label"
              valueKey="value"
              errors={errors[index] || {}}
              onBlur={() => handleBlur(index)}
            />

            <CustomSelectInput
              field={{
                label: "Tax Code",
                key: "tax_category_code",
                required: true,
                size: 2,
              }}
              formData={item}
              handleChange={(e) =>
                handleItemChange(index, "tax_category_code", e.target.value)
              }
              options={taxTypes}
              labelKey="Description"
              valueKey="Code"
              errors={errors[index] || {}}
              onBlur={() => handleBlur(index)}
            />

            <CustomStringInput
              field={{
                label: "Total Tax",
                key: "tax_amount",
                required: false,
                size: 3,
              }}
              formData={item}
              handleChange={(e) =>
                handleItemChange(index, "tax_amount", e.target.value)
              }
              errors={errors[index] || {}}
              onBlur={() => handleBlur(index)}
            />
          </Grid>

          <Grid container spacing={2} alignItems="center" sx={{ mt: 1 }}>
            <CustomSelectInput
              field={{
                label: "Item Code",
                key: "item_code",
                required: true,
                size: 3,
              }}
              formData={item}
              handleChange={(e) =>
                handleItemChange(index, "item_code", e.target.value)
              }
              options={itemCodes}
              labelKey="Description"
              valueKey="Code"
              errors={errors[index] || {}}
              onBlur={() => handleBlur(index)}
            />

            <CustomSelectInput
              field={{
                label: "Unit of Measure",
                key: "unit_of_measure",
                required: true,
                size: 2,
              }}
              formData={item}
              handleChange={(e) =>
                handleItemChange(index, "unit_of_measure", e.target.value)
              }
              options={uomCodes}
              labelKey="Name"
              valueKey="Code"
              errors={errors[index] || {}}
              onBlur={() => handleBlur(index)}
            />

            <CustomStringInput
              field={{
                label: "Discount",
                key: "discount_amount",
                required: false,
                size: 2,
              }}
              formData={item}
              handleChange={(e) =>
                handleItemChange(index, "discount_amount", e.target.value)
              }
              errors={errors[index] || {}}
              onBlur={() => handleBlur(index)}
              type="number"
            />

            <CustomStringInput
              field={{
                label: "Tax Rate",
                key: "tax_rate",
                required: false,
                size: 2,
              }}
              formData={item}
              handleChange={(e) =>
                handleItemChange(index, "tax_rate", e.target.value)
              }
              errors={errors[index] || {}}
              onBlur={() => handleBlur(index)}
              type="number"
            />

            <CustomStringInput
              field={{
                label: "Total Amount",
                key: "total_amount",
                required: false,
                size: 3,
              }}
              formData={item}
              handleChange={(e) =>
                handleItemChange(index, "total_amount", e.target.value)
              }
              errors={errors[index] || {}}
              onBlur={() => handleBlur(index)}
            />
          </Grid>
        </Paper>
      ))}

      <Button variant="contained" onClick={handleAddItem} sx={{ mt: 2 }}>
        Add Item
      </Button>
    </>
  );
}
