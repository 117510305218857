import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchSupplierById } from "../../api/suppliers/suppliersSlice";
import { Box, Paper, Typography, useTheme, Divider } from "@mui/material";
import "../../styles/DocumentDetails.css";
import TopBar from "../../components/TopBar";
import DetailsPageActions from "./details/DetailsPageAction";
import InfoCard from "../common/InfoCard";
import { getCountryName, getMISCCode, getStateName } from "../../utils/helper";
import useDocumentTitle from "../../hooks/useDocumentTitle";

export default function SupplierDetails({ config }) {
  useDocumentTitle("Supplier Details");
  const { profile_id } = useParams();
  const dispatch = useDispatch();
  const supplier = useSelector((state) => state.suppliers.selectedSupplier);

  const loading = useSelector((state) => state.suppliers.loading);
  const theme = useTheme();

  const states = useSelector((state) => state.states.states);
  const countryCodes = useSelector((state) => state.countryCodes.countryCodes);
  const msicCodes = useSelector((state) => state.msicCodes.msicCodes);

  useEffect(() => {
    if (profile_id) {
      dispatch(fetchSupplierById(profile_id));
    }
  }, [dispatch, profile_id]);

  if (loading) {
    return (
      <Box className="centered-box">
        <Typography variant="h6" component="div">
          Loading...
        </Typography>
      </Box>
    );
  }

  const {
    supplierSection,
    actions,
    title,
    addressSection,
    identifiersSection,
  } = config;

  return (
    <Box>
      {supplier && (
        <TopBar title={title} subTitle={supplier?.profile_id}>
          <DetailsPageActions actions={actions} />
        </TopBar>
      )}

      <Paper>
        {supplier && (
          <InfoCard
            document={{
              ...supplier,
              state: getStateName(supplier.state, states),
              country_code: getCountryName(supplier.country_code, countryCodes),
              msic_code: getMISCCode(supplier.msic_code, msicCodes),
            }}
            data={supplierSection}
          />
        )}

        <Box sx={{ display: "flex", alignItems: "center", my: 2 }}>
          <Divider sx={{ flex: 1 }} />
          <Typography
            variant="subtitle2"
            sx={{
              mx: 2,
              color: theme.palette.text.disabled,
              textAlign: "center",
            }}
          >
            ADDRESS
          </Typography>
          <Divider sx={{ flex: 1 }} />
        </Box>

        {supplier && (
          <InfoCard
            document={{
              ...supplier,
              state: getStateName(supplier.state, states),
              country_code: getCountryName(supplier.country_code, countryCodes),
              msic_code: getMISCCode(supplier.msic_code, msicCodes),
            }}
            data={addressSection}
          />
        )}

        <Box sx={{ display: "flex", alignItems: "center", my: 2 }}>
          <Divider sx={{ flex: 1 }} />
          <Typography
            variant="subtitle2"
            sx={{
              mx: 2,
              color: theme.palette.text.disabled,
              textAlign: "center",
            }}
          >
            Business Identifiers
          </Typography>
          <Divider sx={{ flex: 1 }} />
        </Box>

        {supplier && (
          <InfoCard
            document={{
              ...supplier,
              state: getStateName(supplier.state, states),
              country_code: getCountryName(supplier.country_code, countryCodes),
              msic_code: getMISCCode(supplier.msic_code, msicCodes),
            }}
            data={identifiersSection}
          />
        )}
      </Paper>
    </Box>
  );
}
