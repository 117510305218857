import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  customers: [],
  selectedCustomer: null,
  currentPage: 1,
  totalPages: 1,
  totalDocuments: 0,
  isCustomerCreated: false,
  loading: false,
  error: null,
};

const customersSlice = createSlice({
  name: "customers",
  initialState,
  reducers: {
    fetchCustomersRequest(state) {
      state.loading = true;
      state.error = null;
    },
    fetchCustomersSuccess(state, action) {
      state.loading = false;
      state.customers = action.payload.customers;
      state.currentPage = action.payload.currentPage;
      state.totalPages = action.payload.totalPages;
      state.totalDocuments = action.payload.totalDocuments;
    },
    fetchCustomersFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    fetchCustomerById(state, action) {
      state.loading = true;
      state.error = null;
    },
    fetchCustomerByIdSuccess(state, action) {
      state.loading = false;
      state.selectedCustomer = action.payload;
    },
    fetchCustomerByIdFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    createCustomerRequest(state, action) {
      state.loading = true;
      state.error = false;
    },
    createCustomerSuccess(state, action) {
      state.loading = false;
      state.error = false;
      state.isCustomerCreated = true;
    },
    createCustomerFailure(state, action) {
      state.loading = false;
      state.error = true;
    },
    markCustomerCreated(state, action) {
      state.isCustomerCreated = action.payload;
    },
  },
});

export const {
  fetchCustomersRequest,
  fetchCustomersSuccess,
  fetchCustomersFailure,
  fetchCustomerById,
  fetchCustomerByIdSuccess,
  fetchCustomerByIdFailure,
  createCustomerRequest,
  createCustomerSuccess,
  createCustomerFailure,
  markCustomerCreated,
} = customersSlice.actions;

export default customersSlice.reducer;
