import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  suppliers: [],
  selectedSupplier: null,
  currentPage: 1,
  totalPages: 1,
  totalDocuments: 0,
  loading: false,
  isSupplierCreated: false,
  error: null,
};

const suppliersSlice = createSlice({
  name: "suppliers",
  initialState,
  reducers: {
    fetchSuppliersRequest(state) {
      state.loading = true;
      state.error = null;
    },
    fetchSuppliersSuccess(state, action) {
      state.loading = false;
      state.suppliers = action.payload.suppliers;
      state.currentPage = action.payload.currentPage;
      state.totalPages = action.payload.totalPages;
      state.totalDocuments = action.payload.totalDocuments;
    },
    fetchSuppliersFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    fetchSupplierById(state, action) {
      state.loading = true;
      state.error = null;
    },
    fetchSupplierByIdSuccess(state, action) {
      state.loading = false;
      state.selectedSupplier = action.payload;
    },
    fetchSupplierByIdFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    createSupplierRequest(state, action) {
      state.loading = true;
      state.error = null;
      state.isSupplierCreated = false;
    },
    createSupplierSuccess(state, action) {
      state.loading = false;
      state.error = null;
      state.isSupplierCreated = true;
    },
    createSupplierFailure(state, action) {
      state.loading = false;
      state.isSupplierCreated = false;
      state.error = action.payload;
    },
    markSupplierCreated(state, action) {
      state.isSupplierCreated = action.payload;
    },
  },
});

export const {
  fetchSuppliersRequest,
  fetchSuppliersSuccess,
  fetchSuppliersFailure,
  fetchSupplierById,
  fetchSupplierByIdSuccess,
  fetchSupplierByIdFailure,
  createSupplierRequest,
  createSupplierSuccess,
  createSupplierFailure,
  markSupplierCreated,
} = suppliersSlice.actions;

export default suppliersSlice.reducer;
