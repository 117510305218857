const pricingEngineForDocuments = (items, headerDiscount) => {
  const subtotal = items.reduce(
    (acc, item) => acc + item.quantity * item.unit_rate,
    0,
  );
  const totalTaxAmount = items.reduce((acc, item) => acc + item.tax_amount, 0);

  const totalItemDiscount = items.reduce((acc, item) => {
    const baseAmount = item.quantity * item.unit_rate;
    const discountAmount =
      item.discount_type === "percentage"
        ? (baseAmount * item.discount_amount) / 100
        : item.discount_amount;

    return acc + discountAmount;
  }, 0);

  const totalExcludingTax = subtotal - totalItemDiscount;
  const totalIncludingTax = totalExcludingTax + Number(totalTaxAmount);
  const totalHeaderDiscount = Number(headerDiscount);
  const totalPayableAmount = totalIncludingTax - Number(headerDiscount);

  return {
    subtotal,
    totalTaxAmount: Number(totalTaxAmount.toFixed(2)),
    totalItemDiscount: Number(totalItemDiscount.toFixed(2)),
    totalExcludingTax: Number(totalExcludingTax.toFixed(2)),
    totalIncludingTax: Number(totalIncludingTax.toFixed(2)),
    totalPayableAmount: Number(totalPayableAmount.toFixed(2)),
    totalHeaderDiscount: Number(totalHeaderDiscount.toFixed(2)),
    grandTotal: Number(totalPayableAmount.toFixed(2)),
  };
};

export default pricingEngineForDocuments;
