import { IconButton, styled } from "@mui/material";

export const DeleteButton = styled(IconButton)(({ disabled }) => ({
  color: disabled ? "#cccccc" : "#ffb3b3",
  borderRadius: "4px",
  cursor: disabled ? "not-allowed" : "pointer",
  position: "absolute",
  fontSize: "1.2rem",
  top: "-2px",
  right: "2px",
}));
