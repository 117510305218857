import { configureStore } from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";
import invoicesReducer from "../api/invoices/invoicesSlice";
import authReducer from "../api/auth/authSlice";
import pdfTemplateReducer from "../api/pdfTemplates/pdfTemplateSlice";
import creditNotesReducer from "../api/creditNotes/creditNotesSlice";
import refundsReducer from "../api/refunds/refundsSlice";
import debitNotesReducer from "../api/debitNotes/debitNotesSlice";
import businessProfilesReducer from "../api/businessProfiles/businessProfilesSlice";
import statesReducer from "../api/constants/statesSlice";
import countryCodesReducer from "../api/constants/countryCodesSlice";
import msicCodesReducer from "../api/constants/msicCodesSlice";
import snackbarReducer from "../api/snackbarSlice";
import customersReducer from "../api/customers/customersSlice";
import currenciesReducer from "../api/constants/currencySlice";
import taxTypesReducer from "../api/constants/taxTypesSlice";
import paymentMeansReducer from "../api/constants/paymentMeansSlice";
import itemCodesReducer from "../api/constants/itemCodesSlice";
import uomCodesReducer from "../api/constants/uomSlice";
import suppliersReducer from "../api/suppliers/suppliersSlice";
import rootSaga from "../api/rootSaga";

const sagaMiddleware = createSagaMiddleware();

const store = configureStore({
  reducer: {
    invoices: invoicesReducer,
    auth: authReducer,
    pdfTemplates: pdfTemplateReducer,
    creditNotes: creditNotesReducer,
    refunds: refundsReducer,
    debitNotes: debitNotesReducer,
    businessProfiles: businessProfilesReducer,
    states: statesReducer,
    countryCodes: countryCodesReducer,
    msicCodes: msicCodesReducer,
    snackbar: snackbarReducer,
    customers: customersReducer,
    currencies: currenciesReducer,
    taxTypes: taxTypesReducer,
    paymentMeans: paymentMeansReducer,
    itemCodes: itemCodesReducer,
    uomCodes: uomCodesReducer,
    suppliers: suppliersReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(sagaMiddleware),
});

sagaMiddleware.run(rootSaga);

export default store;
