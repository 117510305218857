import React from "react";
import { Route } from "react-router-dom";
import InvoiceList from "../resources/Invoices/InvoiceList";
import InvoiceDetails from "../resources/Invoices/InvoiceDetails";
import CreditNoteList from "../resources/creditNotes/CreditNoteList";
import CreditNoteDetails from "../resources/creditNotes/CreditNoteDetails";
import RefundList from "../resources/refunds/RefundList";
import RefundDetails from "../resources/refunds/RefundDetails";
import DebitNoteList from "../resources/debitNotes/DebitNoteList";
import DebitNoteDetails from "../resources/debitNotes/DebitNoteDetails";
import BusinessProfileList from "../resources/businessProfiles/BusinessProfileList";
import BusinessProfileDetails from "../resources/businessProfiles/BusinessProfileDetails";
import CustomerForm from "../resources/customers/create/CustomerForm";
import CustomerDetails from "../resources/customers/CustomerDetails";
import CustomerList from "../resources/customers/CustomerList";
import MYSInvoiceForm from "../resources/Invoices/create/mys/MYSInvoiceForm";
import BusinessProfileForm from "../resources/businessProfiles/create/BusinessProfileForm";
import MYSCreditNoteForm from "../resources/creditNotes/create/mys/MYSCreditNoteForm";
import MYSDebitNoteForm from "../resources/debitNotes/create/mys/MYSDebitNoteForm";
import MYSRefundForm from "../resources/refunds/create/mys/MYSRefundForm";
import SupplierList from "../resources/suppliers/SupplierList";
import SupplierDetails from "../resources/suppliers/SupplierDetails";
import SupplierForm from "../resources/suppliers/create/SupplierForm";
import MYSSBInvoiceForm from "../resources/selfBilledInvoices/create/mys/MYSSBInvoiceForm";
import SBInvoiceList from "../resources/selfBilledInvoices/SBInvoiceList";
import SBInvoiceDetails from "../resources/selfBilledInvoices/SBInvoiceDetails";
import SBCreditNoteList from "../resources/selfBilledcreditNotes/SBCreditNoteList";
import SBCreditNoteDetails from "../resources/selfBilledcreditNotes/SBCreditNoteDetails";
import MYSSBCreditNoteForm from "../resources/selfBilledcreditNotes/create/mys/MYSSBCreditNoteForm";
import SBDebitNoteList from "../resources/selfBilledDebitNotes/SBDebitNoteList";
import SBDebitNoteDetails from "../resources/selfBilledDebitNotes/SBDebitNoteDetails";
import MYSSBDebitNoteForm from "../resources/selfBilledDebitNotes/create/mys/MYSSBDebitNoteForm";
import SBRefundDetails from "../resources/selfBilledRefunds/SBRefundDetails";
import SBRefundList from "../resources/selfBilledRefunds/SBRefundList";
import MYSSBRefundForm from "../resources/selfBilledRefunds/create/mys/MYSSBRefundForm";

const components = {
  MYSInvoiceFormComponent: MYSInvoiceForm,
  MYSCreditNoteFormComponent: MYSCreditNoteForm,
  MYSDebitNoteFormComponent: MYSDebitNoteForm,
  MYSRefundFormComponent: MYSRefundForm,
  MYSSBInvoiceFormComponent: MYSSBInvoiceForm,
  MYSSBCreditNoteFormComponent: MYSSBCreditNoteForm,
  MYSSBDebitNoteFormComponent: MYSSBDebitNoteForm,
  MYSSBRefundFormComponent: MYSSBRefundForm,
};

function getComponent(component) {
  return components[component];
}

const AuthenticatedRoutes = ({ features }) => {
  return features.map((feature) => (
    <React.Fragment key={feature.key}>
      {feature.key === "invoices" && feature.list && (
        <Route
          path={`/${feature.key}`}
          element={<InvoiceList config={feature.list} />}
        />
      )}

      {feature.key === "invoices" && feature.details && (
        <Route
          path={`/${feature.key}/:document_id`}
          element={<InvoiceDetails config={feature.details} />}
        />
      )}

      {feature.key === "invoices" && feature.create && (
        <Route
          path={`/${feature.key}/create`}
          element={React.createElement(getComponent(feature.create.component), {
            config: feature.create,
          })}
        />
      )}

      {feature.key === "credit-notes" && feature.list && (
        <Route
          path={`/${feature.key}`}
          element={<CreditNoteList config={feature.list} />}
        />
      )}

      {feature.key === "credit-notes" && feature.details && (
        <Route
          path={`/${feature.key}/:document_id`}
          element={<CreditNoteDetails config={feature.details} />}
        />
      )}

      {feature.key === "credit-notes" && feature.create && (
        <Route
          path={`/${feature.key}/create`}
          element={React.createElement(getComponent(feature.create.component), {
            config: feature.create,
          })}
        />
      )}

      {feature.key === "refunds" && feature.list && (
        <Route
          path={`/${feature.key}`}
          element={<RefundList config={feature.list} />}
        />
      )}

      {feature.key === "refunds" && feature.details && (
        <Route
          path={`/${feature.key}/:document_id`}
          element={<RefundDetails config={feature.details} />}
        />
      )}

      {feature.key === "refunds" && feature.create && (
        <Route
          path={`/${feature.key}/create`}
          element={React.createElement(getComponent(feature.create.component), {
            config: feature.create,
          })}
        />
      )}

      {feature.key === "debit-notes" && feature.list && (
        <Route
          path={`/${feature.key}`}
          element={<DebitNoteList config={feature.list} />}
        />
      )}

      {feature.key === "debit-notes" && feature.details && (
        <Route
          path={`/${feature.key}/:document_id`}
          element={<DebitNoteDetails config={feature.details} />}
        />
      )}

      {feature.key === "debit-notes" && feature.create && (
        <Route
          path={`/${feature.key}/create`}
          element={React.createElement(getComponent(feature.create.component), {
            config: feature.create,
          })}
        />
      )}

      {feature.key === "business-profiles" && feature.list && (
        <Route
          path={`/${feature.key}`}
          element={<BusinessProfileList config={feature.list} />}
        />
      )}

      {feature.key === "business-profiles" && feature.details && (
        <Route
          path={`/${feature.key}/:profile_id`}
          element={<BusinessProfileDetails config={feature.details} />}
        />
      )}

      {feature.key === "business-profiles" && feature.create && (
        <Route
          path={`/${feature.key}/create`}
          element={<BusinessProfileForm config={feature.create} />}
        />
      )}

      {feature.key === "customers" && feature.list && (
        <Route
          path={`/${feature.key}`}
          element={<CustomerList config={feature.list} />}
        />
      )}

      {feature.key === "customers" && feature.details && (
        <Route
          path={`/${feature.key}/:profile_id`}
          element={<CustomerDetails config={feature.details} />}
        />
      )}

      {feature.key === "customers" && feature.create && (
        <Route
          path={`/${feature.key}/create`}
          element={<CustomerForm config={feature.create} />}
        />
      )}

      {feature.key === "suppliers" && feature.list && (
        <Route
          path={`/${feature.key}`}
          element={<SupplierList config={feature.list} />}
        />
      )}

      {feature.key === "suppliers" && feature.details && (
        <Route
          path={`/${feature.key}/:profile_id`}
          element={<SupplierDetails config={feature.details} />}
        />
      )}

      {feature.key === "suppliers" && feature.create && (
        <Route
          path={`/${feature.key}/create`}
          element={<SupplierForm config={feature.create} />}
        />
      )}

      {feature.key === "self-billed-invoices" && feature.list && (
        <Route
          path={`/${feature.key}`}
          element={<SBInvoiceList config={feature.list} />}
        />
      )}

      {feature.key === "self-billed-invoices" && feature.details && (
        <Route
          path={`/${feature.key}/:document_id`}
          element={<SBInvoiceDetails config={feature.details} />}
        />
      )}

      {feature.key === "self-billed-invoices" && feature.create && (
        <Route
          path={`/${feature.key}/create`}
          element={React.createElement(getComponent(feature.create.component), {
            config: feature.create,
          })}
        />
      )}

      {feature.key === "self-billed-credit-notes" && feature.create && (
        <Route
          path={`/${feature.key}/create`}
          element={React.createElement(getComponent(feature.create.component), {
            config: feature.create,
          })}
        />
      )}

      {feature.key === "self-billed-credit-notes" && feature.list && (
        <Route
          path={`/${feature.key}`}
          element={<SBCreditNoteList config={feature.list} />}
        />
      )}

      {feature.key === "self-billed-credit-notes" && feature.details && (
        <Route
          path={`/${feature.key}/:document_id`}
          element={<SBCreditNoteDetails config={feature.details} />}
        />
      )}

      {feature.key === "self-billed-debit-notes" && feature.list && (
        <Route
          path={`/${feature.key}`}
          element={<SBDebitNoteList config={feature.list} />}
        />
      )}

      {feature.key === "self-billed-debit-notes" && feature.create && (
        <Route
          path={`/${feature.key}/create`}
          element={React.createElement(getComponent(feature.create.component), {
            config: feature.create,
          })}
        />
      )}

      {feature.key === "self-billed-debit-notes" && feature.details && (
        <Route
          path={`/${feature.key}/:document_id`}
          element={<SBDebitNoteDetails config={feature.details} />}
        />
      )}

      {feature.key === "self-billed-refunds" && feature.create && (
        <Route
          path={`/${feature.key}/create`}
          element={React.createElement(getComponent(feature.create.component), {
            config: feature.create,
          })}
        />
      )}

      {feature.key === "self-billed-refunds" && feature.list && (
        <Route
          path={`/${feature.key}`}
          element={<SBRefundList config={feature.list} />}
        />
      )}

      {feature.key === "self-billed-refunds" && feature.details && (
        <Route
          path={`/${feature.key}/:document_id`}
          element={<SBRefundDetails config={feature.details} />}
        />
      )}
    </React.Fragment>
  ));
};

export default AuthenticatedRoutes;
