import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  InputLabel,
  Typography,
  Button,
  FormControl,
  Autocomplete,
  TextField,
} from "@mui/material";
import TopBar from "../../../../components/TopBar";
import { useDispatch, useSelector } from "react-redux";
import CreateInvoiceActions from "../CreateInvoiceActions";
import ProfileCard from "../../../common/ProfileCard";
import {
  fetchBusinessProfilesRequest,
  markBusinessProfileCreated,
} from "../../../../api/businessProfiles/businessProfilesSlice";
import {
  fetchCustomersRequest,
  markCustomerCreated,
} from "../../../../api/customers/customersSlice";
import { fetchCurrenciesRequest } from "../../../../api/constants/currencySlice";
import { fetchTaxTypesRequest } from "../../../../api/constants/taxTypesSlice";
import { fetchUomCodesRequest } from "../../../../api/constants/uomSlice";
import { fetchPaymentTermsRequest } from "../../../../api/constants/paymentMeansSlice";
import { fetchItemCodesRequest } from "../../../../api/constants/itemCodesSlice";
import MYSItemDetails from "./MYSItemDetails";
import { useNavigate } from "react-router-dom";
import { getCountryName, getStateName } from "../../../../utils/helper";
import useDocumentTitle from "../../../../hooks/useDocumentTitle";
import CustomStringInput from "../../../../components/CustomStringInput";
import CustomSelectInput from "../../../../components/CustomSelectInput";
import CustomDateInput from "../../../../components/CustomDateInput";
import pricingEngineForDocuments from "../../../../utils/pricingEngineForDocuments";

const sellerSection = [
  {
    label: "Contact Person",
    key: "name",
  },
  {
    label: "Company Name",
    key: "name",
  },
  {
    label: "Address",
    key: "",
  },
  {
    label: "Country",
    key: "country_code",
  },
  {
    label: "Phone",
    key: "phone",
  },
  {
    label: "Email",
    key: "email",
  },
  {
    label: "CR",
    key: "registration_number",
  },
  {
    label: "TIN",
    key: "tin",
  },
  {
    label: "VAT/GST No",
    key: "sst_registration_number",
  },
];

const buyerSection = [
  {
    label: "Contact Person",
    key: "name",
  },
  {
    label: "Company Name",
    key: "name",
  },
  {
    label: "Address",
    key: "",
  },
  {
    label: "Country",
    key: "country_code",
  },
  {
    label: "Phone",
    key: "phone",
  },
  {
    label: "Email",
    key: "email",
  },
  {
    label: "CR",
    key: "registration_number",
  },
  {
    label: "TIN",
    key: "tin",
  },
  {
    label: "VAT/GST No",
    key: "sst_registration_number",
  },
];

const requiredFields = [
  "document_number",
  "document_date",
  "document_time",
  "transaction_type",
  "document_type",
  "document_currency_code",
  "billed_by",
  "billed_to",
  "item_details",
  "sub_total",
  "grand_total",
  "total_excluding_tax",
  "total_including_tax",
  "total_payable_amount",
];

export default function MYSInvoiceForm({ config }) {
  useDocumentTitle("Invoice Form");
  const navigate = useNavigate();
  const [items, setItems] = useState([
    {
      description: "",
      item_code: "",
      unit_of_measure: "",
      quantity: 1,
      unit_rate: 1,
      base_amount: 0,
      net_amount: 0,
      total_amount: 1,
      tax_category_code: "",
      tax_rate: "",
      tax_amount: 0,
      discount_amount: 0,
      discount_type: "number",
    },
  ]);
  const [errors, setErrors] = useState({});

  const [formData, setFormData] = useState({
    document_number: "",
    document_date: "",
    document_time: new Date().toISOString().slice(11, 19) + "Z",
    transaction_type: "B2B",
    document_type: "01",
    document_currency_code: "MYR",
    billed_by: "",
    billed_to: "",
    item_details: items,
    sub_total: 0,
    grand_total: 0,
    total_excluding_tax: 0,
    total_including_tax: 0,
    total_payable_amount: 0,
    payment_mode: "",
    payment_terms: "",
    header_discount: 0,
    total_tax_amount: 0,
    total_item_discount: 0,
  });
  const countryCodes = useSelector((state) => state.countryCodes.countryCodes);
  const states = useSelector((state) => state.states.states);
  const currencies = useSelector((state) => state.currencies.currencies);
  const taxTypes = useSelector((state) => state.taxTypes.taxTypes);
  const paymentMeans = useSelector((state) => state.paymentMeans.paymentMeans);
  const itemCodes = useSelector((state) => state.itemCodes.itemCodes);
  const uomCodes = useSelector((state) => state.uomCodes.uomCodes);
  const { businessProfiles, totalDocuments: totalBusinessProfiles } =
    useSelector((state) => state.businessProfiles);
  const { customers, totalDocuments: totalCustomers } = useSelector(
    (state) => state.customers,
  );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      fetchBusinessProfilesRequest({
        url: "/business-profiles",
        page: 1,
        limit: totalBusinessProfiles,
      }),
    );
    dispatch(
      fetchCustomersRequest({
        url: "/customers",
        page: 1,
        limit: totalCustomers,
      }),
    );
    dispatch(fetchCurrenciesRequest({ search_key: "currency" }));
    dispatch(fetchTaxTypesRequest({ search_key: "tax_type" }));
    dispatch(fetchPaymentTermsRequest({ search_key: "payment_means" }));
    dispatch(fetchItemCodesRequest({ search_key: "item_code" }));
    dispatch(fetchUomCodesRequest({ search_key: "uom" }));
    dispatch(markBusinessProfileCreated(false));
    dispatch(markCustomerCreated(false));
  }, [dispatch, totalBusinessProfiles, totalCustomers]);

  useEffect(() => {
    const {
      subtotal,
      totalTaxAmount,
      totalItemDiscount,
      totalExcludingTax,
      totalIncludingTax,
      totalPayableAmount,
      totalHeaderDiscount,
      grandTotal,
    } = pricingEngineForDocuments(items, formData.header_discount);

    setFormData((prevData) => ({
      ...prevData,
      item_details: items,
      sub_total: subtotal,
      total_tax_amount: totalTaxAmount,
      total_item_discount: totalItemDiscount,
      total_excluding_tax: totalExcludingTax,
      total_including_tax: totalIncludingTax,
      total_payable_amount: totalPayableAmount,
      header_discount: totalHeaderDiscount,
      grand_total: grandTotal,
    }));
  }, [items, formData.header_discount]);

  const { title, actions } = config;

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevData) => ({ ...prevData, [name]: value }));

    setErrors((prevErrors) => {
      const newErrors = { ...prevErrors };
      if (newErrors[name]) {
        delete newErrors[name];
      }
      return newErrors;
    });
  };

  const handleSelectChange = (name) => (e) => {
    const { value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleRedirect = (path) => {
    navigate(path, {
      state: {
        isFromDocuments: true,
        target: "/invoices/create",
      },
    });
  };

  return (
    <Box sx={{ p: 4 }} className="InvoiceDetails">
      <TopBar title={title}>
        <CreateInvoiceActions
          formData={formData}
          requiredFields={requiredFields}
          setErrors={setErrors}
          actions={actions}
        />
      </TopBar>
      <Grid container spacing={2}>
        <CustomStringInput
          field={{
            label: "Document Number",
            key: "document_number",
            required: true,
            size: 6,
          }}
          formData={formData}
          handleChange={handleChange}
          errors={errors}
        />

        <CustomDateInput
          field={{
            label: "Document Date",
            key: "document_date",
            required: true,
            size: 6,
          }}
          formData={formData}
          handleChange={handleChange}
          errors={errors}
        />

        <CustomSelectInput
          field={{
            label: "Payment Mode",
            key: "payment_mode",
            required: false,
            size: 6,
          }}
          formData={formData}
          handleChange={handleChange}
          errors={errors}
          options={paymentMeans}
          labelKey="Payment Method"
          valueKey="Code"
        />

        <CustomStringInput
          field={{
            label: "Payment Terms",
            key: "payment_terms",
            required: false,
            size: 6,
          }}
          formData={formData}
          handleChange={handleChange}
          errors={errors}
        />

        <Grid item xs={12} sm={6}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <InputLabel sx={{ color: "black", mb: -1.5, fontSize: "0.875rem" }}>
              Billed By: <span style={{ color: "red" }}>*</span>
            </InputLabel>
            <Button
              variant="text"
              onClick={() => handleRedirect("/business-profiles/create")}
              sx={{
                ml: 2,
                fontSize: "0.875rem",
                padding: "6px 16px",
                color: "#011F3B",
              }}
            >
              Add
            </Button>
          </Box>
          <FormControl fullWidth margin="normal">
            <Autocomplete
              options={businessProfiles}
              getOptionLabel={(option) =>
                `${option.name} | ${option.email} | ${option.registration_number}`
              }
              isOptionEqualToValue={(option, value) => option._id === value._id}
              value={formData.billed_by || null}
              onChange={(event, newValue) => {
                const { org, ...withoutOrg } = newValue || {};
                handleSelectChange("billed_by")({
                  target: {
                    name: "billed_by",
                    value: newValue ? withoutOrg : null,
                  },
                });
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  size="small"
                  error={!!errors.billed_by}
                  sx={{ fontSize: "0.875rem" }}
                />
              )}
              sx={{ fontSize: "0.875rem" }}
            />
          </FormControl>

          {formData.billed_by && (
            <Box sx={{ mt: 2, fontSize: "0.875rem", color: "gray" }}>
              <ProfileCard
                title="Billed By"
                subtitle="Vendor Info"
                fields={sellerSection}
                document={{
                  ...formData.billed_by,
                  state: getStateName(formData?.billed_by.state, states),
                  country_code: getCountryName(
                    formData.billed_by.country_code,
                    countryCodes,
                  ),
                }}
                applyRightMargin={true}
                fixedHeight={true}
              />
            </Box>
          )}
        </Grid>

        <Grid item xs={12} sm={6}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <InputLabel sx={{ color: "black", mb: -1.5, fontSize: "0.875rem" }}>
              Billed To: <span style={{ color: "red" }}>*</span>
            </InputLabel>
            <Button
              variant="text"
              onClick={() => handleRedirect("/customers/create")}
              sx={{
                ml: 2,
                fontSize: "0.875rem",
                padding: "6px 16px",
                color: "#011F3B",
              }}
            >
              Add
            </Button>
          </Box>
          <FormControl fullWidth margin="normal">
            <Autocomplete
              options={customers}
              getOptionLabel={(option) =>
                `${option.name} | ${option.email} | ${option.registration_number}`
              }
              isOptionEqualToValue={(option, value) => option._id === value._id}
              value={formData.billed_to || null}
              onChange={(event, newValue) => {
                const { org, ...withoutOrg } = newValue || {};
                handleSelectChange("billed_to")({
                  target: {
                    name: "billed_to",
                    value: newValue ? withoutOrg : null,
                  },
                });
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  size="small"
                  error={!!errors.billed_to}
                  sx={{ fontSize: "0.875rem" }}
                />
              )}
              sx={{ fontSize: "0.875rem" }}
            />
          </FormControl>

          {formData.billed_to && (
            <Box sx={{ mt: 2, fontSize: "0.875rem", color: "gray" }}>
              <ProfileCard
                title="Billed To"
                subtitle="Customer Info"
                fields={buyerSection}
                document={{
                  ...formData.billed_to,
                  state: getStateName(formData.billed_to.state, states),
                  country_code: getCountryName(
                    formData.billed_to.country_code,
                    countryCodes,
                  ),
                }}
                applyRightMargin={true}
                fixedHeight={true}
              />
            </Box>
          )}
        </Grid>

        <Grid item xs={12}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography variant="h6">Item Details</Typography>
            <CustomSelectInput
              field={{
                label: "Currency",
                key: "document_currency_code",
                required: true,
                size: 2,
              }}
              formData={formData}
              handleChange={handleChange}
              errors={errors}
              options={currencies}
              labelKey="Code"
              valueKey="Code"
            />
          </Box>
          <MYSItemDetails
            items={items}
            setItems={setItems}
            taxTypes={taxTypes}
            itemCodes={itemCodes}
            uomCodes={uomCodes}
          />
        </Grid>

        <Grid item xs={12}>
          <Box className="summary-details-container">
            <Box className="summary-item">
              <Typography className="summary-label">Subtotal:</Typography>
              <span className="summary-value">{formData.sub_total}</span>
            </Box>
            <Box className="summary-item">
              <Typography className="summary-label">
                Total Excluding Tax:
              </Typography>
              <span className="summary-value">
                {formData.total_excluding_tax}
              </span>
            </Box>
            <Box className="summary-item">
              <Typography className="summary-label">
                Total Including Tax:
              </Typography>
              <span className="summary-value">
                {formData.total_including_tax}
              </span>
            </Box>
            <Box className="summary-item">
              <Typography className="summary-label">
                Total Payable Amount:
              </Typography>
              <span className="summary-value">
                {formData.total_payable_amount}
              </span>
            </Box>
            <Box className="summary-item">
              <Typography className="summary-label">
                Header Discount:
              </Typography>
              <CustomStringInput
                field={{
                  key: "header_discount",
                  required: false,
                  size: 2,
                }}
                formData={formData}
                handleChange={handleChange}
                errors={errors}
                type="number"
              />
            </Box>
            <Box className="summary-item">
              <Typography className="summary-label">
                Total Tax Amount:
              </Typography>
              <span className="summary-value">{formData.total_tax_amount}</span>
            </Box>
            <Box className="summary-item">
              <Typography className="summary-label">
                Total Item Discount:
              </Typography>
              <span className="summary-value">
                {formData.total_item_discount}
              </span>
            </Box>
            <hr className="summary-divider" />
            <Box className="summary-item">
              <Typography className="summary-label" sx={{ fontWeight: "bold" }}>
                Grand Total:
              </Typography>
              <span className="summary-value">{formData.grand_total}</span>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
